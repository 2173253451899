import { useEffect, useState, ReactNode } from 'react';
import { Field, FieldProps } from 'formik';
import { Input } from '@nextui-org/react';

interface IInputSelection<T> {
    id: string;
    label: string;
    subLabel?: string;
    placeholder?: string;

    containerClassName?: string;
    inputContainerClassName?: string;
    optionsContainerClassName?: string;
    itemContainerClassName?: string;

    handlerFindOptions: (finder: string) => Promise<Array<T>>;
    optionContainerTemplate?: (options: T[], select: (value: T | any) => void, clear: () => void) => ReactNode;
    optionItemTemplate: (option: T, select: (value: T | any) => void, clear: () => void) => ReactNode;
    valueItemTemplate: (value: T | any, remove: () => void, clear: () => void) => ReactNode;

    value: T | null | any;
    required?: boolean;
    error?: string;
    touched?: boolean;
}

const InputSelection = <T,>(props: IInputSelection<T>) => {
    const [finder, setFinder] = useState('');
    const [options, setOptions] = useState<T[]>([]);

    const handlerFindOptions = async (finderString: string) => {
        if (!finder) return setOptions(Object.assign([], []));

        const optionsItems = await props.handlerFindOptions(finderString);
        return setOptions(Object.assign([], optionsItems))
    }

    useEffect(() => {
        handlerFindOptions(finder);
    }, [finder]);

    const clear = () => {
        setFinder('');
        setOptions([]);
    }

    return <Field id={props.id} name={props.id}>
        {
            ({ form, field }: FieldProps) => {
                const select = (value: T) => {
                    form.setFieldValue(props.id, value);
                }

                const remove = () => {
                    form.setFieldValue(props.id, null)
                }

                return <div className={''}>
                    <div className={''}>
                        <Input
                            id={`${props.id}`}
                            label={props.label}
                            description={props.subLabel}
                            value={finder}
                            onChange={(e: any) => {
                                setFinder(e.target.value);
                            }}
                            color={(props.error) ? 'danger' : 'default'}
                            isInvalid={(props.error) ? true : false}
                            errorMessage={(props.error) && props.error}

                        />
                    </div>
                    {
                        props.optionContainerTemplate ?
                            props.optionContainerTemplate(options, select, clear)
                            :
                            <div

                                style={{ display: options.length > 0 ? 'block' : 'none' }}
                            >
                                {options.map(option => props.optionItemTemplate(option, select, clear))}
                            </div>
                    }
                    <div
                        className='flex flex-col gap-3'
                    >
                        {props.value && props.valueItemTemplate(props.value, remove, clear)}
                    </div>
                </div>
            }
        }
    </Field>
}

export default InputSelection;