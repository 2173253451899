import { RouteObject } from "react-router-dom";
import CreateObjective from "./create/createObjective/createObjective";
import CreateKeyResult from "./create/createKeyResult/createKeyResult";
import Detail from "./create/detail/detail";
import ObjetiveListPage from "./list/list";
import ObjectiveDetail from "./detail/detail";
import EditObjective from "./edit/editObjective";

const objectiveRoutes: Array<RouteObject> = [
    { index: true, element: <ObjetiveListPage /> },
    { path: 'create', element: <CreateObjective /> },
    { path: 'create/keyResults', element: <CreateKeyResult /> },
    { path: 'create/detail', element: <Detail /> },
    { path: 'detail/:id', element: <ObjectiveDetail /> },
    { path: 'edit/:id', element: <EditObjective /> }
]

export default objectiveRoutes;