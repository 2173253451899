import * as Yup from 'yup';

const createKeyResultValidation = () => {
    return Yup.object().shape({
        name: Yup.string()
            .min(5, 'Demasiado corto.')
            .max(100, 'Demasiado largo.')
            .required('Es requerido.'),
        description: Yup.string().notRequired(),
        categories: Yup.array().notRequired(),
        manager: Yup.object().shape({
            id: Yup.number().required('Es requerido.')
        }).required('Es requerido.'),
        notify: Yup.array().notRequired(),
        meditionValue: Yup.number().min(0, 'Debe ser mayor o igual a 0').required('Es requerido'),
        meditionIntendedValue: Yup.number().min(0, 'Debe ser mayor o igual a 0').required('Es requerido'),
        startDate: Yup.date().required('Es requerido.'),
        endDate: Yup.date().required('Es requerido.')//.min(Yup.ref('startDate'), 'La fecha debe ser mayor al inicio.'),
    })
}

export default createKeyResultValidation;