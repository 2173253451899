import styles from './Steps.module.scss';

interface IStep {
    title: string;
    active: boolean;
}

interface ISteps {
    steps: Array<IStep>;
}

const Steps = ({ steps }: ISteps) => {

    const isFirstStep = (index: number) => {
        return index === 0;
    }

    const isLastStep = (index: number) => {
        return index === (steps.length - 1);
    }

    const hasNextStep = (index: number) => {
        return steps[index + 1];
    }

    const isNextStepActive = (index: number) => {
        if (hasNextStep(index)) return steps[index + 1].active;
        return false;
    }

    return <div className={styles.stepsContainer}>
        {steps.map((step, index) => {
            return <>
                <div className={`${styles.stepLine} ${step.active && styles.active} ${isFirstStep(index) && styles.first}`}></div>
                <div className={styles.stepContent}>
                    <div className={`${styles.stepCircle} ${step.active && styles.active}`}>
                        <div className={styles.stepCounter}>{index + 1}</div>
                        <div className={`${styles.stepTitle} ${step.active && styles.active}`}>{step.title}</div>
                    </div>
                </div>
                <div className={`${styles.stepLine} ${isLastStep(index) && styles.last} ${isNextStepActive(index) && styles.active}`}></div>
            </>
        })}
    </div>
}

export default Steps;