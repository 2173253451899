import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputSelectionMultiple from "../../../../components/Input/InputSelectionMultiple/InputSelectionMultiple";
import GoalCategoryDTO from "../../dto/goalCategory.dto"
import GoalCategoryRepository from "../../goalCategory.repository";

import styles from './selectGoalCategory.module.css';
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import CreateGoalCategoryDTO from "../../dto/createGoalCategory.dto";
import { ListboxItem } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";

interface ISelectGoalCategory {
    containerClassName: string;
    values: GoalCategoryDTO[];
    disableCreate?: boolean;
    endContent?: ReactNode;
}

const SelectGoalCategory = (props: ISelectGoalCategory) => {
    const { t } = useTranslation();
    return <InputSelectionMultiple<GoalCategoryDTO | CreateGoalCategoryDTO>
        id='categories'
        label={t('objective.inputs.category.label')}
        placeholder='Ejemplo: Estrategia'
        containerClassName={props.containerClassName}
        handlerFindOptions={async (finder) => {
            return await GoalCategoryRepository.find({ where: { name: `lk=${finder}` } })
        }}
        endContent={props.endContent}
        optionsContainerClassName={``}
        optionItemTemplate={(option, insert, clear) => {
            return <ListboxItem key={option.name.toString()}
                onClick={() => {
                    insert(props.values.length, option);
                    clear()
                }}>{option.name}</ListboxItem>
        }}
        itemContainerClassName={styles.valuesContainer}
        valueItemTemplate={(value, index, remove) => {
            return <div className={`bg-primary flex gap-3 items-center rounded py-1 px-3 text-primary-foreground`}>
                <span>{value.name}</span>
                <FontAwesomeIcon icon={faTimes} onClick={() => remove(index)} className={styles.times} />
            </div>
        }}
        createTemplate={(options, finder, insert, clear) => {
            if (props.disableCreate || options.some(option => option.name.toLowerCase() === finder.toLowerCase())) return null
            return <ListboxItem key={finder.toString()} color="primary" onClick={() => {
                insert(props.values.length, { name: finder, description: finder });
                clear();
            }}> <FontAwesomeIcon icon={faPlus} /> {t('objective.inputs.category.new')} "{finder}"</ListboxItem>
        }}
        values={props.values}
    />
}

export default SelectGoalCategory;