import { createBrowserRouter } from "react-router-dom";
import objectiveRoutes from "./pages/objective/router";
import authRoutes from "./pages/auth/router";
import keyResultRoutes from "./pages/keyResult/router";
import Navbar from "./components/Navbar/navbar";
import initiativeRoutes from "./pages/initiative/router";

const appRoutes = createBrowserRouter([
    {
        path: '/',
        element: <Navbar />,
        children: [
            { path: 'objective', children: objectiveRoutes },
            { path: 'auth', children: authRoutes },
            { path: 'keyResult', children: keyResultRoutes },
            { path: 'initiative', children: initiativeRoutes }
        ]
    }
])

export default appRoutes;