import { Outlet, useNavigate } from "react-router-dom";
import { Avatar, Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Listbox, ListboxItem, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuToggle, Navbar as NextNavbar, Switch } from "@nextui-org/react";
import logo from '../../assets/img/giveit.svg'
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faMessage, faMoon, faRightFromBracket, faSun, faUser, faWrench } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "next-themes";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Navbar = () => {
    const profile = useSelector((state: RootState) => state.profile);
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    return <div>
        <NextNavbar isBordered>
            <NavbarContent className="md:hidden" justify="start">
                <NavbarMenuToggle />
            </NavbarContent>
            <NavbarMenu>
                <Listbox variant="flat">
                    <ListboxItem key="home">
                        <Link href="https://dashboard.giveit.es/dashboard">{t('menu.items.home')}</Link>
                    </ListboxItem>
                    <ListboxItem key="institutional">
                        <Link href="https://dashboard.giveit.es/institutional">{t('menu.items.institutional')}</Link>
                    </ListboxItem>
                    <ListboxItem key="ranking">
                        <Link href="https://dashboard.giveit.es/ranking">{t('menu.items.ranking')}</Link>
                    </ListboxItem>
                    <ListboxItem key="challenges">
                        <Link href="https://dashboard.giveit.es/challenges">{t('menu.items.objectives')}</Link>
                    </ListboxItem>
                    <ListboxItem key="metrics">
                        <Link href="https://dashboard.giveit.es/metrics">{t('menu.items.challenges')}</Link>
                    </ListboxItem>
                </Listbox>
            </NavbarMenu>
            {/** DESKTOP LOGO */}
            <NavbarBrand className="gap-2 hidden md:flex">
                <img className=" w-7" src={logo} />
                <span className="text-primary font-paytoneOne text-2xl">give it</span>
            </NavbarBrand>
            {/** MOBILE LOGO */}
            <NavbarContent className="md:hidden" justify="center">
                <NavbarBrand className="gap-2 flex">
                    <img className=" w-7" src={logo} />
                    <span className="text-primary font-paytoneOne text-2xl">give it</span>
                </NavbarBrand>
            </NavbarContent>
            <NavbarContent className="hidden md:flex" justify="center">
                <NavbarItem>
                    <Link href="https://dashboard.giveit.es/dashboard">{t('menu.items.home')}</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link href="https://dashboard.giveit.es/institutional">{t('menu.items.institutional')}</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link href="https://dashboard.giveit.es/ranking">{t('menu.items.ranking')}</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link href="https://dashboard.giveit-tech.com/objective">{t('menu.items.objectives')}</Link>
                </NavbarItem>
                <NavbarItem>
                    <Link href="https://dashboard.giveit.es/challenges">{t('menu.items.challenges')}</Link>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <Button size="sm" onClick={() => theme.theme === 'light' ? theme.setTheme('dark') : theme.setTheme('light')} className="rounded-full p-0" color={theme.theme === 'light' ? 'warning' : 'primary'}>{theme.theme === 'light' ? <FontAwesomeIcon icon={faSun} /> : <FontAwesomeIcon icon={faMoon} />}</Button>
                <Dropdown>
                    <DropdownTrigger className="cursor-pointer">
                        {i18n.resolvedLanguage?.toLocaleUpperCase()}
                    </DropdownTrigger>
                    <DropdownMenu>
                        <DropdownItem onClick={() => i18n.changeLanguage('es')}>ES</DropdownItem>
                        <DropdownItem onClick={() => i18n.changeLanguage('en')}>EN</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <Dropdown>
                    <DropdownTrigger>
                        <Avatar
                            isBordered
                            color="primary"
                            src={`https://giveit-system-assets.s3.amazonaws.com/${profile.image || 'avatar_giveit.webp'}`}
                        />
                    </DropdownTrigger>
                    <DropdownMenu variant="flat">
                        <DropdownItem>
                            <a className="flex items-center gap-3" href="https://dashboard.giveit.es/profile" target="_self">
                                <FontAwesomeIcon
                                    icon={faUser}
                                />
                                {t('menu.profile.me')}
                            </a>
                        </DropdownItem>

                        <DropdownItem>
                            <a className="flex items-center gap-3" href="mailto:it@giveit.es" target="_blank">
                                <FontAwesomeIcon
                                    icon={faMessage}
                                />
                                {t('menu.profile.support')}
                            </a>
                        </DropdownItem>
                        <DropdownItem>
                            <a className="flex items-center gap-3" href="https://different-lentil-d9f.notion.site/Gu-a-de-Uso-dd3577b4a8204877859a58a80bd7f4cd" target="_blank">
                                <FontAwesomeIcon
                                    icon={faBook}
                                />
                                {t('menu.profile.reglamento')}
                            </a>
                        </DropdownItem>
                        <DropdownItem>
                            <a className="flex items-center gap-3" href="https://dashboard.giveit.es/login" target="_self">
                                <FontAwesomeIcon
                                    icon={faRightFromBracket}
                                />
                                {t('menu.profile.signOut')}
                            </a>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </NavbarContent>
        </NextNavbar>
        <div className="container mx-auto px-3"><Outlet /></div>
    </div >
}

export default Navbar;
