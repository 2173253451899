import { Field } from 'formik';
import MeditionTypeEnum from '../../enum/meditionType.enum';
import { Select, SelectItem } from '@nextui-org/react';

interface ISelectMeditionType {
    id: string;
    containerClassName?: string;
    label?: string;
    subLabel?: string;
    required?: boolean;
}

const selectMeditionType = (props: ISelectMeditionType) => {
    return <Field as='select' name={props.id}>
        {({ field }: { field: any }) => (
            <Select label={props.label} description={props.subLabel} defaultSelectedKeys={[MeditionTypeEnum.NUMERIC]} {...field} >
                <SelectItem key={MeditionTypeEnum.NUMERIC} value={MeditionTypeEnum.NUMERIC}>Numerica</SelectItem>
                <SelectItem key={MeditionTypeEnum.PERCENTAGE} value={MeditionTypeEnum.PERCENTAGE}>Porcentual</SelectItem>
                <SelectItem key={MeditionTypeEnum.CURRENCY} value={MeditionTypeEnum.CURRENCY}>En moneda</SelectItem>
            </Select>
        )}
    </Field>
}

export default selectMeditionType;