import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import CreateInitiativeValidation from "./createValidation";
import NormalInput from "../../../components/Input/NormalInput/NormalInput";
import InputTextArea from "../../../components/Input/InputTextArea/InputTextArea";
import { Button, Select, SelectItem } from "@nextui-org/react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import KeyResultRepository from "../../../modules/keyResult/keyResult.repository";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const CreateInitiative = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const initialValuesForm = {
        name: '',
        description: '',
        status: 'PENDING'
    }
    const [keyResult, setKeyResult] = useState<null | any>(null);
    const params = useParams();

    const getKeyResult = async () => {
        const keyResultResponse = await KeyResultRepository.findOne(params.id || 0, { relations: ['objective'] })
        setKeyResult(keyResultResponse);
    }

    useEffect(() => {
        getKeyResult();
    }, [])

    return <div className="container mx-auto text-foreground">
        <h2 className='text-primary font-paytoneOne gap-3 flex items-center'>
            <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => navigate(-1)} />
            {t('initiative.create')}
        </h2>
        <p>{t('initiative.subTitle')}</p>
        <Formik
            initialValues={initialValuesForm}
            validationSchema={CreateInitiativeValidation()}
            onSubmit={async (values) => {
                await axios.post('/initiative', {
                    keyResult: {
                        id: Number(params.id)
                    },
                    objective: {
                        id: keyResult?.objective?.id
                    },
                    ...values
                });
                navigate(`/keyResult/detail/${params.id}`)
            }}
        >
            {({ errors, touched, isValid }) => {
                return <Form className="flex flex-col gap-5 py-3">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <NormalInput
                            id='name'
                            label={t('initiative.inputs.name.label')}
                            required={true}
                            error={errors.name}
                            touched={touched.name}
                        />
                        <Field as='select' name={'status'}>
                            {({ field }: { field: any }) => (
                                <Select label={t('initiative.inputs.status.label')} defaultSelectedKeys={['PENDING']} {...field} >
                                    <SelectItem key={'PENDING'} value={'PENDING'}>Pendiente</SelectItem>
                                    <SelectItem key={'IN_PROGRESS'} value={'IN_PROGRESS'}>En progreso</SelectItem>
                                    <SelectItem key={'COMPLETED'} value={'COMPLETED'}>Completo</SelectItem>
                                </Select>
                            )}
                        </Field>
                    </div>
                    <InputTextArea
                        id='description'
                        label={t('initiative.inputs.description.label')}
                    />
                    <Button color="primary" isDisabled={!isValid} type="submit">{t('initiative.buttons.confirm')}</Button>
                </Form>
            }}

        </Formik>
    </div>
}

export default CreateInitiative;