import { faBullseye, faKey, faRocket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ObjectiveDTO from "../../dtos/objective.dto";
import DateUtil from '../../../../utils/date.util';
import KeyResultDTO from '../../../keyResult/dto/keyResult.dto';
import { Avatar, AvatarGroup, Card, Tooltip, User } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

interface IObjectiveDetail {
    objective: ObjectiveDTO;
}

const ObjectiveDetail = ({ objective }: IObjectiveDetail) => {
    const { t } = useTranslation();
    return <div className='mt-5'>
        <div className='flex gap-3 items-center'>
            <FontAwesomeIcon icon={faBullseye} className='text-primary' />
            <p className="font-bold">{t('objective.title')}: </p>
            <p>{objective.name}</p>
        </div>
        <div className="grid grid-cols-3">
            <div className='flex flex-col items-start'>
                <p className="font-bold">{t('objective.manager')}</p>
                <User name={`${objective?.manager.firstName} ${objective?.manager.lastName}`} avatarProps={{
                    src: objective?.manager.image
                }} />
            </div>
            <div className='flex flex-col items-start'>
                <p className="font-bold">{t('objective.period')}</p>
                <div className="bg-primary text-white text-xs py-2 px-3 rounded">
                    {DateUtil.formatDateLabel(new Date(objective?.startDate) || new Date())} - {DateUtil.formatDateLabel(new Date(objective?.endDate) || new Date())}
                </div>
            </div>
            <div className='flex flex-col items-start'>
                <p className="font-bold">{t('objective.inputs.points.label')}</p>
                <div className='flex items-center gap-3'>
                    <FontAwesomeIcon icon={faRocket} className='text-primary' />
                    <div className=''>{objective.points}</div>
                </div>
            </div>
            <div className='flex flex-col items-start'>
                <p className="font-bold">{t('objective.inputs.category.label')}</p>
                <span>{objective.categories.map(category => category.name).join(' / ') || '-'}</span>
            </div>
            <div className='flex flex-col items-start'>
                <p className="font-bold">{t('objective.inputs.privacity.label')}</p>
                <span className='text-primary'>{t('objective.inputs.privacity.options.public')}</span>
            </div>
            <div className='flex flex-col items-start'>
                <p className="font-bold">{t('objective.inputs.descendant.label')}</p>
                <span>-</span>
            </div>
        </div>
        <div>
            <div className="flex items-center gap-3">
                <FontAwesomeIcon icon={faKey} />
                <p className='font-bold'>{t('objective.keyResults')} ({objective.keyResults.length})</p>
            </div>
            <div className="flex flex-col gap-3">
                {objective.keyResults.map(keyResult => <div className='col-md-12'>
                    <KeyResultItemDetail keyResult={keyResult} />
                </div>)}
            </div>
        </div>
    </div>
}

interface IKeyResultItemDetail {
    keyResult: KeyResultDTO;
}

const KeyResultItemDetail = ({ keyResult }: IKeyResultItemDetail) => {
    const { t } = useTranslation();
    return <Card>
        <div className="grid grid-cols-12 p-3">
            <div className="flex items-center">
                <div className='py-3 px-5 rounded bg-primary text-primary-foreground'>KR</div>
            </div>
            <div className="flex items-center col-span-3">
                <p className=''>{keyResult.name}</p>
            </div>
            <div className="flex flex-col col-span-4">
                <p className="font-bold">{t('keyResult.inputs.colaborators.label')}</p>
                <AvatarGroup isBordered color='primary' isGrid>
                    {keyResult?.colaborators.map(colaborator => {
                        return <Tooltip color='primary' content={`${colaborator.firstName} ${colaborator.lastName}`}>
                            <Avatar className='cursor-pointer' name={`${colaborator.firstName} ${colaborator.lastName}`} src={colaborator.image ? `https://giveit-system-assets.s3.amazonaws.com/${colaborator.image}` : ''} />
                        </Tooltip>
                    })}
                </AvatarGroup>
            </div>
            <div className="flex flex-col col-span-4">
                <p className="font-bold">{t('keyResult.inputs.category.label')}</p>
                <span>{keyResult.categories.map(category => category.name).join(' / ') || '-'}</span>
            </div>
        </div>
    </Card>
}

export default ObjectiveDetail;