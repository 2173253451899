import axios, { InternalAxiosRequestConfig } from 'axios'

const TIMEOUT = 1 * 60 * 1000

const setupAxios = () => {
  axios.defaults.timeout = TIMEOUT
  axios.defaults.baseURL = 'https://api.giveit-tech.com'
  //axios.defaults.baseURL = 'http://192.168.0.71:3000'

  const onRequest = (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
  axios.interceptors.request.use(onRequest)
}

export default setupAxios
