import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import appRoutes from './router';
import setupAxios from './config/axios.config';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store';
import './index.scss';
import './config/i18n.config'
import { NextUIProvider, Spinner } from '@nextui-org/react';
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { Suspense } from 'react';

setupAxios();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <NextUIProvider>
      <NextThemesProvider attribute="class" defaultTheme="light">
        <ReduxProvider store={store}>
          <Suspense fallback={
            <div className='h-screen flex items-center justify-center'>
              <Spinner />
            </div>
          }>
            <div className='prose font-poppins max-w-none'>
              <RouterProvider router={appRoutes} />
            </div>
          </Suspense>
        </ReduxProvider>
      </NextThemesProvider>
    </NextUIProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
