import UserDTO from "../../dto/user.dto"
import UserRepository from "../../user.repository";

import styles from './selectUsers.module.css';
import AvatarSelectionTemplate from "../userAvatarSelectionItem/userAvatarSelectionItem";
import AvatarSelectedTemplate from "../userAvatarSelectedItem/userAvatarSelectedItem";
import InputSelectionMultiple from "../../../../components/Input/InputSelectionMultiple/InputSelectionMultiple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../../components/Button/Button";
import { faTimes, faTimesCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { Listbox, ListboxItem, User } from "@nextui-org/react";
import { ReactNode } from "react";
//import { Card, ListGroup } from "react-bootstrap";

interface ISelectUser {
    id: string;
    label: string;
    subLabel?: string;
    containerClassName: string;
    values: UserDTO[];
    required?: boolean;
    error?: string;
    endContent?: ReactNode;
}

const SelectUsers = (props: ISelectUser) => {
    return <InputSelectionMultiple<UserDTO>
        id={props.id}
        label={props.label}
        subLabel={props.subLabel}
        placeholder=''
        endContent={props.endContent}
        containerClassName={props.containerClassName}
        handlerFindOptions={async (finder) => {
            return await UserRepository.find({ where: [{ email: `lk=${finder}` }, { firstName: `lk=${finder}` }, { lastName: `lk=${finder}` }] })
        }}
        optionsContainerClassName={''}
        optionContainerTemplate={(options, select, clear) => {
            if (options.length === 0) return null;
            return <div className="relative">
                <Listbox className="border rounded absolute bg-background z-20">
                    {options.map((option, index) => <ListboxItem key={option.id.toString()} onClick={() => {
                        select(index, option);
                        clear();
                    }}>
                        <User name={`${option.firstName} ${option.lastName}`} avatarProps={{ src: option.image ? `https://giveit-system-assets.s3.amazonaws.com/${option.image}` : 'https://giveit-system-assets.s3.amazonaws.com/avatar_giveit.webp' }} />
                    </ListboxItem>)}
                </Listbox>
            </div>
        }}
        optionItemTemplate={(option, insert, clear) => {
            return null;
        }}
        itemContainerClassName={styles.valuesContainer}
        valueItemTemplate={(value, index, remove) => {
            return <div className="border mt-2 rounded p-2 flex items-center justify-between gap-3">
                <User name={`${value.firstName} ${value.lastName}`} avatarProps={{ src: value.image ? `https://giveit-system-assets.s3.amazonaws.com/${value.image}` : 'https://giveit-system-assets.s3.amazonaws.com/avatar_giveit.webp' }} />
                <FontAwesomeIcon icon={faTimes} onClick={() => remove(index)} />
            </div>
        }}
        values={props.values}
    />
}

export default SelectUsers;