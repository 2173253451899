import { configureStore } from "@reduxjs/toolkit";
import objectiveReducer from "./modules/objective/objective.reducer";
import profileReducer from "./modules/auth/profile.reducer";

export const store = configureStore({
    reducer: {
        profile: profileReducer,
        objective: objectiveReducer
    },
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch