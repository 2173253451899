import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import EnterpriseDTO from '../enterprise/dto/enterprise.dto'
import GoalCategoryDTO from '../goalCategory/dto/goalCategory.dto'
import KeyResultDTO from '../keyResult/dto/keyResult.dto'
import UserDTO from '../user/dto/user.dto'
import ObjectiveVisibilityEnum from './enum/objectiveVisibility.enum'
import CreateObjectiveDTO from './dtos/createObjective.dto'
import CreateKeyResultDTO from '../keyResult/dto/createKeyResult.dto'

export interface ObjectiveState {
  name: string | null
  description: string | null
  categories: Array<GoalCategoryDTO>
  manager: UserDTO | null
  startDate: Date | null
  endDate: Date | null
  points: number | null
  visibility: ObjectiveVisibilityEnum | null
  notify: UserDTO[]
  keyResults: CreateKeyResultDTO[]
  descendant: KeyResultDTO | null
}

const initialState: ObjectiveState = {
  name: '',
  description: '',
  categories: [],
  manager: null,
  startDate: null,
  endDate: null,
  points: null,
  visibility: null,
  notify: [],
  keyResults: [],
  descendant: null
}

export const objectiveSlice = createSlice({
  name: 'objective',
  initialState,
  reducers: {
    saveObjetive: (
      state: ObjectiveState,
      action: PayloadAction<CreateObjectiveDTO>
    ) => {
      state.name = action.payload.name
      state.description = action.payload.description
      state.categories = action.payload.categories
      state.manager = action.payload.manager
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
      state.points = action.payload.points
      state.visibility = action.payload.visibility
      state.descendant = action.payload.descendant
      state.notify = action.payload.notify
    },
    saveKeyResult: (
      state: ObjectiveState,
      action: PayloadAction<CreateKeyResultDTO>
    ) => {
      const keyResults = state.keyResults
      keyResults.push(action.payload)
      state.keyResults = keyResults
    },
    clear: (state: ObjectiveState) => {
      state = initialState
    }
  }
})

export const { saveObjetive, saveKeyResult, clear } = objectiveSlice.actions

export default objectiveSlice.reducer
