import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import EnterpriseDTO from '../enterprise/dto/enterprise.dto'

export interface ProfileState {
  email: string
  firstName: string
  lastName: string
  image: string
  enterprise: Partial<EnterpriseDTO>
}

const initialState: ProfileState = {
  email: '',
  firstName: '',
  lastName: '',
  image: '',
  enterprise: {
    cuit: '',
    fantasyName: ''
  }
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    saveProfile: (state: ProfileState, action: PayloadAction<ProfileState>) => {
      state.email = action.payload.email
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.image = action.payload.image
      state.enterprise = {
        cuit: action.payload.enterprise.cuit,
        fantasyName: action.payload.enterprise.fantasyName
      }
    }
  }
})

export const { saveProfile } = profileSlice.actions

export default profileSlice.reducer
