import { Field } from 'formik';
import styles from './InputTimeFrame.module.css';
import InputLabel from '../InputLabel/InputLabel';
import { useState } from 'react';
import { Button, Input } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

export interface IInputTimeFrame {
    id: string;
    placeholder?: string;
    required?: boolean;
    label?: string;
    className?: string;
    errors?: {
        startDate?: string,
        endDate?: string
    }
    toucheds?: {
        startDate: boolean;
        endDate: boolean;
    }
    onSelectQuarter?: (q: 'Q1' | 'Q2' | 'Q3' | 'Q4') => void
}

const TimeFrameBox = ({ q, onClick, active }: { q: 'Q1' | 'Q2' | 'Q3' | 'Q4', onClick: (q: 'Q1' | 'Q2' | 'Q3' | 'Q4') => void, active: boolean }) => {
    return <Button color={`primary`} variant={`${active ? 'solid' : 'bordered'}`} onClick={() => onClick(q)}>{q}</Button>
}

const TimeFrameSelection = ({ onClick, active }: { onClick: (q: 'Q1' | 'Q2' | 'Q3' | 'Q4') => void, active: 'Q1' | 'Q2' | 'Q3' | 'Q4' | null }) => {
    return <div className='flex flex-wrap justify-center gap-3'>
        <TimeFrameBox q='Q1' onClick={onClick} active={active === 'Q1'} />
        <TimeFrameBox q='Q2' onClick={onClick} active={active === 'Q2'} />
        <TimeFrameBox q='Q3' onClick={onClick} active={active === 'Q3'} />
        <TimeFrameBox q='Q4' onClick={onClick} active={active === 'Q4'} />
    </div>
}


const InputTimeFrame = ({ id, placeholder, required, label, className, errors, onSelectQuarter, toucheds }: IInputTimeFrame) => {
    const [activeItem, setActiveItem] = useState<'Q1' | 'Q2' | 'Q3' | 'Q4' | null>(null);
    const { t } = useTranslation();
    return <div className={className}>
        <TimeFrameSelection onClick={(q) => {
            setActiveItem(q);
            if (onSelectQuarter) {
                onSelectQuarter(q)
            }
        }} active={activeItem} />
        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 mt-3'>
            <Field as='date' id={`start${id}`} name={`start${id}`} placeholder={placeholder} autoComplete='off'>
                {({ form, field }: { form: any, field: any }) => {
                    const { setFieldValue } = form;
                    const { value } = field;
                    return (<Input id={`start${id}`} type='date' dateFormat={'dd/MM/yyyy'} {...field} value={value} onValueChange={val => {
                        setActiveItem(null)
                        setFieldValue(`start${id}`, val)
                    }}
                        isInvalid={errors?.startDate}
                        errorMessage={errors?.startDate}
                        color={errors?.startDate && 'danger'}
                    />)
                }}
            </Field>
            <Field as='date' id={`end${id}`} name={`end${id}`} placeholder={placeholder} autoComplete='off'>
                {({ form, field }: { form: any, field: any }) => {
                    const { setFieldValue } = form;
                    const { value } = field;
                    return (<Input id={`end${id}`} type='date' dateFormat={'dd/MM/yyyy'} {...field} value={value} onValueChange={val => {
                        setFieldValue(`end${id}`, val)
                        setActiveItem(null)
                    }}
                        isInvalid={errors?.endDate}
                        errorMessage={errors?.endDate}
                        color={errors?.endDate && 'danger'}
                    />)
                }}
            </Field>
        </div>
    </div>
}

export default InputTimeFrame;