import styles from './keyResultItem.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import CreateKeyResultDTO from '../../../../../../modules/keyResult/dto/createKeyResult.dto';
import { Card, CardBody } from '@nextui-org/react';
//import { Card } from 'react-bootstrap';

const KeyResultItem = ({ keyResult }: { keyResult: CreateKeyResultDTO }) => {
    return <Card>
        <CardBody>
            <div className='flex gap-3 items-center'>
                <div className='bg-primary px-3 py-2 rounded text-primary-foreground'>KR</div>
                <div>{keyResult.name}</div>
                <FontAwesomeIcon icon={faTrash} color='color-red-500' />
            </div>
        </CardBody>
    </Card>
}

export default KeyResultItem;