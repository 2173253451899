import axios from 'axios'
import CreateKeyResultDTO from './dto/createKeyResult.dto'
import KeyResultDTO from './dto/keyResult.dto'
import FindOptionsDTO from '../common/dto/findOptions.dto'

namespace KeyResultRepository {
  export const save = async (
    keyResult: CreateKeyResultDTO
  ): Promise<KeyResultDTO> => {
    try {
      return (await axios.post<KeyResultDTO>(`/keyResult`, keyResult)).data
    } catch (err) {
      throw new Error()
    }
  }

  export const update = async (
    id: number,
    keyResult: KeyResultDTO | Partial<KeyResultDTO>
  ): Promise<KeyResultDTO> => {
    try {
      return (await axios.put<KeyResultDTO>(`/keyResult/${id}`, keyResult)).data
    } catch (err) {
      throw new Error()
    }
  }

  export const find = async (
    options: FindOptionsDTO<KeyResultDTO>
  ): Promise<KeyResultDTO[]> => {
    try {
      const formatedOptions = encodeURIComponent(JSON.stringify(options))
      const keyResults = (
        await axios.get<KeyResultDTO[]>(`/keyResult?query=${formatedOptions}`)
      ).data

      return keyResults.map(keyResult => {
        keyResult.startDate = new Date(keyResult.startDate)
        keyResult.endDate = new Date(keyResult.endDate)
        return keyResult
      })
    } catch (err) {
      throw Error()
    }
  }

  export const findOne = async (
    id: number | string,
    options: FindOptionsDTO<KeyResultDTO>
  ): Promise<KeyResultDTO> => {
    try {
      const formatedOptions = encodeURIComponent(JSON.stringify(options))
      const keyResult = (
        await axios.get<KeyResultDTO>(
          `/keyResult/${id}?query=${formatedOptions}`
        )
      ).data
      keyResult.startDate = new Date(keyResult.startDate)
      keyResult.endDate = new Date(keyResult.endDate)
      return keyResult
    } catch (err) {
      throw Error()
    }
  }

  export const remove = async (id: number): Promise<void> => {
    await axios.delete(`/keyResult/${id}`)
  }
}

export default KeyResultRepository
