import * as Yup from 'yup';

const CreateInitiativeValidation = () => {
    return Yup.object().shape({
        name: Yup.string()
            .min(5, 'Demasiado corto.')
            .max(100, 'Demasiado largo.')
            .required('Es requerido.'),
        description: Yup.string().notRequired()
    })
}
export default CreateInitiativeValidation;