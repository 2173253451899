import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import KeyResultDTO from '../../../../../modules/keyResult/dto/keyResult.dto';
import DateUtil from '../../../../../utils/date.util';
import { useNavigate } from 'react-router-dom';
import StatusEnum from '../../../../../modules/keyResult/enum/status.enum';
import { Formik } from 'formik';
import KeyResultRepository from '../../../../../modules/keyResult/keyResult.repository';
import StatusInput from '../../../../../components/Input/StatusInput/statusInput';
import { Card, CardBody } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

const KeyResultItem = ({ keyResult }: { keyResult: KeyResultDTO }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return <Card className='ml-3 mr-2'>
        <CardBody className='grid grid-cols-12'>
            <div className='flex items-center justify-center'>
                <div className='py-3 px-5 rounded bg-primary text-white'>KR</div>
            </div>
            <div className='flex items-center gap-3 col-span-5'>
                <a className='text-foreground' href={`/keyResult/detail/${keyResult.id}`} >{keyResult.name}</a>
            </div>
            <div className='flex flex-col items-center gap-2 col-span-2'>
                <div className='text-sm'>{t('keyResult.period')}</div>
                <div className='h-full flex items-center'>
                    <div className='bg-primary text-white text-xs py-2 px-3 rounded'>{DateUtil.formatDateLabel(new Date(keyResult.startDate))} - {DateUtil.formatDateLabel(new Date(keyResult.endDate))}</div>
                </div>
            </div>
            <div className='flex flex-col items-center gap-2 col-span-2'>
                <div className='text-sm'>{t('keyResult.progress')}</div>
                <div className="h-full flex items-center">
                    <div style={{ width: 70, height: 70 }}>
                        <CircularProgressbar value={(isNaN(Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) ? 100 : (Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) * 100)} text={`${((isNaN(Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) ? 100 : (Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) * 100)).toFixed(0)}%`} styles={buildStyles({
                            pathColor: `#24B500`,
                            textColor: '#24B500',
                        })} />
                    </div>
                </div>
            </div>
            <div className='flex items-center'>
                <Formik initialValues={{ status: keyResult?.status || StatusEnum.IN_TIME }} onSubmit={(values, actions) => {
                    KeyResultRepository.update(keyResult?.id || 0, { status: values.status })
                }}>
                    {({ values, handleSubmit }) => {
                        return <StatusInput id='status' label='Estado' value={values.status} onSelect={() => handleSubmit()} />
                    }}

                </Formik>
            </div>
        </CardBody>
    </Card>
}

export default KeyResultItem;