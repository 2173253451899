import axios from 'axios'
import LoginResponseDTO from './dto/LoginResponse.dto'
import UserDTO from '../user/dto/user.dto'

namespace AuthRepository {
  export const login = async (
    email: string,
    password: string
  ): Promise<LoginResponseDTO> => {
    try {
      return (
        await axios.post<LoginResponseDTO>(`/auth/login`, {
          email,
          password
        })
      ).data
    } catch (err) {
      throw Error()
    }
  }

  export const getProfile = async (): Promise<UserDTO> => {
    try {
      return (await axios.get<UserDTO>(`/auth/profile`)).data
    } catch (err) {
      throw Error()
    }
  }
}

export default AuthRepository
