import * as Yup from 'yup';

const editObjectiveValidation = () => {
    return Yup.object().shape({
        name: Yup.string()
            .min(5, 'Demasiado corto.')
            .max(100, 'Demasiado largo.')
            .required('Es requerido.'),
        description: Yup.string().notRequired(),
        categories: Yup.array().notRequired(),
        manager: Yup.object().shape({
            id: Yup.number().required('Es requerido.')
        }).required('Es requerido.'),
        startDate: Yup.date().required('Es requerido.'),
        endDate: Yup.date().required('Es requerido.').min(Yup.ref('startDate'), 'La fecha debe ser mayor al inicio.'),
        points: Yup.number().moreThan(0, 'Debe ser mayor a 0.'),
        visibility: Yup.string().required('Es requerido.'),
        notify: Yup.array().notRequired()
    })
}

export default editObjectiveValidation;