import styles from './createKeyResult.module.css';
import { ReactNode } from 'react';
import Title from "../../../../components/Title/Title";
import Paraph from "../../../../components/Paraph/Paraph";
import Steps from "../../../../components/Steps/Steps";
import { Formik, Form, FormikErrors, FormikTouched } from 'formik';
import NormalInput from '../../../../components/Input/NormalInput/NormalInput';
import SelectGoalCategory from '../../../../modules/goalCategory/components/selectGoalCategory/selectGoalCategory.component';
import InputTextArea from '../../../../components/Input/InputTextArea/InputTextArea';
import SelectUser from '../../../../modules/user/component/selectUser/selectUser.component';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreateKeyResultDTO from '../../../../modules/keyResult/dto/createKeyResult.dto';
import createKeyResultValidation from './createKeyResultValidation';
import { saveKeyResult } from '../../../../modules/objective/objective.reducer';
import { RootState } from '../../../../store';
import KeyResultItem from './components/keyResultItem/keyResultItem';
import SelectUsers from '../../../../modules/user/component/selectUsers/selectUsers.component';
import InputTimeFrame from '../../../../components/Input/InputTimeFrame/InputTimeFrame';
import StatusEnum from '../../../../modules/keyResult/enum/status.enum';
import MeditionTypeEnum from '../../../../modules/keyResult/enum/meditionType.enum';
import SelectStatusType from '../../../../modules/keyResult/components/selectStatus/selectStatus';
import SelectMedition from '../../../../modules/keyResult/components/selectMeditionType/selectMeditionType';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { faArrowLeft, faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreateKeyResult = () => {
    const dispatch = useDispatch();
    const keyResults = useSelector((state: RootState) => state.objective.keyResults);
    const objective = useSelector((state: RootState) => state.objective);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const initialValuesForm: CreateKeyResultDTO = {
        name: '',
        description: '',
        categories: [],
        manager: objective.manager,
        colaborators: [],
        notify: [],
        startDate: objective.startDate || new Date(),
        endDate: objective.endDate || new Date(),
        status: StatusEnum.IN_TIME,
        meditionIntendedValue: 0,
        meditionType: MeditionTypeEnum.NUMERIC,
        meditionValue: 0
    }

    const handlerSubmitForm = async (keyResult: CreateKeyResultDTO) => {
        dispatch(saveKeyResult(keyResult));
        navigate('/objective/create/detail');
    }

    const handlerAddAnotherKeyResult = async (keyResult: CreateKeyResultDTO) => {
        dispatch(saveKeyResult(keyResult));
    }


    return <div className='container mx-auto text-foreground'>
        <h2 className='text-primary font-paytoneOne gap-3 flex items-center'>
            <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => navigate(-1)} />
            {t('objective.create')}
        </h2>
        <p>{t('objective.createLabel')}</p>
        <Steps steps={[
            { title: t('objective.title'), active: true },
            { title: t('keyResult.title'), active: true }
        ]} />
        {
            keyResults.length > 0 && (
                <div className='pb-10'>
                    <div className='bg-success-100 text-success-800 border border-success-400 px-3 text-sm rounded'>
                        <p>{t('keyResult.successAdded')}</p>
                    </div>
                    <p className='font-bold text-sm'>{t('keyResult.recentAdded')}:</p>
                    <div className={`flex flex-wrap gap-3`}>
                        {keyResults.map(keyResult => <KeyResultItem keyResult={keyResult} />)}
                    </div>
                </div>
            )
        }
        <Formik<CreateKeyResultDTO>
            initialValues={initialValuesForm}
            validationSchema={createKeyResultValidation()}
            onSubmit={(values, actions) => {
                handlerSubmitForm(values);
            }}
        >
            {({ values, errors, handleReset, setFieldValue, touched, isValid, validateForm, setErrors }: {
                values: CreateKeyResultDTO,
                errors: FormikErrors<CreateKeyResultDTO>,
                handleReset: () => void,
                setFieldValue: (field: string, value: any) => void,
                touched: FormikTouched<CreateKeyResultDTO>,
                isValid: boolean,
                validateForm: (values: CreateKeyResultDTO) => Promise<FormikErrors<CreateKeyResultDTO>>,
                setErrors: (fields: any) => void;
            }): ReactNode => {
                return <Form className='flex flex-col gap-5 py-3'>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                        <NormalInput
                            id='name'
                            label={t('keyResult.inputs.name.label')}
                            required={true}
                            error={errors.name}
                            touched={touched.name}
                        />
                        <SelectGoalCategory
                            containerClassName={styles.createKeyResultFormItem}
                            values={values.categories}
                        />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                        <InputTextArea
                            id='description'
                            label={t('keyResult.inputs.description.label')}
                        />
                        <InputTimeFrame
                            id='Date'
                            label={t('keyResult.inputs.period.label')}
                            required={true}
                            errors={{
                                startDate: errors.startDate as string,
                                endDate: errors.endDate as string
                            }}
                            toucheds={{
                                startDate: touched.startDate as boolean,
                                endDate: touched.endDate as boolean
                            }}
                            onSelectQuarter={(q) => {
                                const dateFrom = new Date();
                                const dateTo = new Date();
                                if (q === 'Q1') {
                                    dateFrom.setDate(1)
                                    dateFrom.setMonth(0);
                                    dateTo.setDate(1);
                                    dateTo.setMonth(3);
                                    dateTo.setDate(0)

                                    const fromText = `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
                                    const toText = `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}`

                                    setFieldValue('startDate', fromText)
                                    setFieldValue('endDate', toText)
                                }
                                if (q === 'Q2') {
                                    dateFrom.setDate(1)
                                    dateFrom.setMonth(3);
                                    dateTo.setDate(1);
                                    dateTo.setMonth(6);
                                    dateTo.setDate(0);

                                    const fromText = `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
                                    const toText = `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}`

                                    setFieldValue('startDate', fromText)
                                    setFieldValue('endDate', toText)
                                }
                                if (q === 'Q3') {
                                    dateFrom.setDate(1)
                                    dateFrom.setMonth(6);
                                    dateTo.setDate(1);
                                    dateTo.setMonth(9);
                                    dateTo.setDate(0);

                                    const fromText = `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
                                    const toText = `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}`


                                    setFieldValue('startDate', fromText)
                                    setFieldValue('endDate', toText)
                                }
                                if (q === 'Q4') {
                                    dateFrom.setDate(1)
                                    dateFrom.setMonth(9);
                                    dateTo.setDate(1);
                                    dateTo.setMonth(12);
                                    dateTo.setDate(0);

                                    const fromText = `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
                                    const toText = `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}`


                                    setFieldValue('startDate', fromText)
                                    setFieldValue('endDate', toText)
                                }
                            }}
                        />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                        <SelectStatusType id={'status'} label={t('keyResult.inputs.status.label')} />
                        <SelectMedition id='meditionType' label={t('keyResult.inputs.meditionType.label')} />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                        <NormalInput
                            id='meditionValue'
                            label={t('keyResult.inputs.meditionValue.label')}
                            required={true}
                            placeholder='10'
                            icon={faStarHalfAlt}
                            error={errors.meditionValue as string}
                            touched={touched.meditionValue as boolean}
                        />
                        <NormalInput
                            id='meditionIntendedValue'
                            label={t('keyResult.inputs.meditionIntendedValue.label')}
                            required={true}
                            placeholder='10'
                            icon={faStar}
                            error={errors.meditionIntendedValue as string}
                            touched={touched.meditionIntendedValue as boolean}
                        />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                        <SelectUsers
                            id='colaborators'
                            label={t('keyResult.inputs.colaborators.label')}
                            containerClassName=''
                            subLabel={t('keyResult.inputs.colaborators.helper')}
                            values={values.colaborators}
                            required={true}
                            error={errors.colaborators as string}
                        />
                        <SelectUsers
                            id='notify'
                            label={t('keyResult.inputs.notify.label')}
                            subLabel={t('keyResult.inputs.notify.helper')}
                            containerClassName=''
                            values={values.notify}
                        />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                        <Button isDisabled={!isValid} type='button' onClick={async () => {
                            const errors = await validateForm(values)
                            if (Object.keys(errors).length > 0) return setErrors(errors)
                            handlerAddAnotherKeyResult(values);
                            handleReset();
                        }}>{t('keyResult.buttons.addOther')}</Button>
                        <Button color='primary' isDisabled={!isValid} type='submit'>{t('keyResult.buttons.continue')}</Button>
                    </div>
                </Form>
            }}
        </Formik>
    </div>
}

export default CreateKeyResult;