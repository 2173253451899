import { useDispatch, useSelector } from 'react-redux';
import ObjectiveRepository from '../../../../modules/objective/objective.repository';
import { RootState } from '../../../../store';
import CreateObjectiveDTO from '../../../../modules/objective/dtos/createObjective.dto';
import ObjectiveDetail from '../../../../modules/objective/components/objectiveDetail/objectiveDetail.component';
import ObjectiveDTO from '../../../../modules/objective/dtos/objective.dto';
import { useNavigate } from 'react-router-dom';
import { clear } from '../../../../modules/objective/objective.reducer';
import { useTranslation } from 'react-i18next';
import { Button } from '@nextui-org/react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Detail = () => {
    const objective = useSelector((state: RootState) => state.objective);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return <div className="container mx-auto text-foreground">
        <h2 className='text-primary font-paytoneOne gap-3 flex items-center'>
            <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => navigate(-1)} />
            {t('objective.resume')}
        </h2>
        <p className=''>{t('objective.resumeLabel')}</p>
        <ObjectiveDetail objective={objective as ObjectiveDTO} />
        <Button color='primary' className='w-full my-3' onClick={async () => {
            await ObjectiveRepository.save(objective as CreateObjectiveDTO)
            dispatch(clear())
            navigate('/objective')
        }}>{t('objective.button.confirm')}</Button>
    </div>
}

export default Detail;