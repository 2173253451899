
import { Accordion, AccordionItem, Avatar } from "@nextui-org/react";
import ObjectiveDTO from "../../../../../modules/objective/dtos/objective.dto";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faShapes } from "@fortawesome/free-solid-svg-icons";
import DateUtil from "../../../../../utils/date.util";
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import KeyResultDTO from "../../../../../modules/keyResult/dto/keyResult.dto";
import StatusEnum from "../../../../../modules/keyResult/enum/status.enum";
import KeyResultItem from "../keyResultItem/keyResultItem";

interface ObjectiveListProps {
    objectives: ObjectiveDTO[];
}

const ObjectiveList = (props: ObjectiveListProps) => {
    const { t } = useTranslation();

    const calculatePercent = (keyResults: Array<KeyResultDTO>) => {
        let percent = 0;
        keyResults.map(keyResult => {
            percent += (isNaN(Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) ? 100 : (Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) * 100)
        })
        percent = percent / keyResults.length;
        return percent;
    }

    const calculateState = (keyResults: Array<KeyResultDTO>) => {
        let percent = 0;
        keyResults.map(keyResult => {
            if (keyResult.status === StatusEnum.IN_TIME)
                percent += 100;
            if (keyResult.status === StatusEnum.LATE)
                percent += 50;
            if (keyResult.status === StatusEnum.AT_RISK)
                percent += 0;
        })
        percent = percent / keyResults.length;

        if (percent > 75)
            return StatusEnum.IN_TIME;
        if (percent >= 50)
            return StatusEnum.LATE;

        return StatusEnum.AT_RISK;
    }

    const getCssStatus = (status: StatusEnum) => {
        const statusCss = {
            AT_RISK: 'bg-red-200 text-red-800 border border border-red-600 rounded p-3 text-sm',
            IN_TIME: 'bg-green-200 text-green-800 border border border-green-600 rounded p-3 text-sm',
            LATE: 'bg-yellow-200 text-yellow-800 border border border-yellow-600 rounded p-3 text-sm'
        }

        return statusCss[status];
    }

    return <Accordion variant="splitted" className="mt-7">
        {props.objectives.map((objective, index) => <AccordionItem classNames={{ heading: 'mt-0 mb-0 py-3', base: 'border-l-4 border-primary' }} key={objective.id} title={
            <div className="grid grid-cols-12">
                <div className="flex flex-col gap-3 col-span-4">
                    <div className="flex gap-2 items-center">
                        <div className="text-primary">
                            <FontAwesomeIcon icon={faBullseye} />
                        </div>
                        <div className="font-light">
                            <strong>{t('objective.title')}</strong>: <a className="text-foreground" href={`/objective/detail/${objective.id}`}>{objective.name}</a>
                        </div>
                    </div>
                    <div className="flex gap-2 items-center text-sm">
                        <div className="text-primary">
                            <FontAwesomeIcon icon={faShapes} />
                        </div>
                        <div className="font-light">
                            <strong>{t('objective.category')}</strong>: {objective.categories.map(category => category.name).join(', ') || '-'}
                        </div>
                    </div>
                </div>
                <div className="hidden md:flex flex-col items-center col-span-2">
                    <div className="font-light text-sm">{t('objective.manager')}</div>
                    <div className="h-full flex items-center">
                        <Avatar src={objective.manager.image ? `https://giveit-system-assets.s3.amazonaws.com/${objective.manager.image}` : 'https://giveit-system-assets.s3.amazonaws.com/avatar_giveit.webp'} name={objective.manager.firstName} />
                    </div>
                </div>
                <div className="hidden md:flex flex-col items-center gap-2 col-span-2">
                    <div className="font-light text-sm">{t('objective.period')}</div>
                    <div className="h-full flex items-center">
                        <div className="bg-primary text-white text-xs py-2 px-3 rounded">
                            {DateUtil.formatDateLabel(objective.startDate)} - {DateUtil.formatDateLabel(objective.endDate)}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-2 col-span-2">
                    <div className="font-light text-sm">{t('objective.progress')}</div>
                    <div className="h-full flex items-center">
                        <div style={{ width: 70, height: 70 }}>
                            <CircularProgressbar value={calculatePercent(objective.keyResults)} text={`${calculatePercent(objective.keyResults).toFixed(0)}%`} styles={buildStyles({
                                pathColor: `#24B500`,
                                textColor: '#24B500',
                            })} />
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex items-center">
                    <div className={getCssStatus(calculateState(objective.keyResults))}>{t(`objective.statuses.${calculateState(objective.keyResults)}`)}</div>
                </div>
            </div>
        }>
            <div className="flex flex-col gap-3 py-3">
                {objective.keyResults.map(keyResult => <KeyResultItem key={keyResult.id} keyResult={keyResult} />)}
            </div>
        </AccordionItem>)}
    </Accordion>
}

export default ObjectiveList;