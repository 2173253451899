import { Field } from "formik";
import { IInput } from "../Input";
import styles from './InputTextArea.module.css';
import InputLabel from "../InputLabel/InputLabel";
import { Textarea } from "@nextui-org/react";

export interface IInputTextArea extends IInput {
    id: string;
    placeholder?: string;
    required?: boolean;
    label?: string;
    subLabel?: string
    className?: string;
}

const InputTextArea = ({ id, required, placeholder, label, subLabel, className }: IInputTextArea) => {
    return <Field as='textarea' id={id} name={id}>
        {({ field }: { field: any }) => (
            <Textarea {...field} isRequired={required} label={label} description={subLabel} />
        )}
    </Field>
}

export default InputTextArea;