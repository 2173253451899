import { ReactNode, useEffect, useState } from 'react';
import Steps from "../../../components/Steps/Steps";
import CreateObjectiveDTO from '../../../modules/objective/dtos/createObjective.dto';
import ObjectiveVisibilityEnum from '../../../modules/objective/enum/objectiveVisibility.enum';
import { Formik, Form, FormikErrors, FormikTouched } from 'formik';
import { faArrowLeft, faRocket } from '@fortawesome/free-solid-svg-icons';
import NormalInput from '../../../components/Input/NormalInput/NormalInput';
import InputTimeFrame from '../../../components/Input/InputTimeFrame/InputTimeFrame';
import SelectGoalCategory from '../../../modules/goalCategory/components/selectGoalCategory/selectGoalCategory.component';
import InputTextArea from '../../../components/Input/InputTextArea/InputTextArea';
import createObjectiveValidation from './editObjectiveValidation';
import SelectUser from '../../../modules/user/component/selectUser/selectUser.component';
import { useDispatch } from 'react-redux';
import { saveObjetive } from '../../../modules/objective/objective.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import ObjectiveDescendence from '../../../modules/objective/components/objectiveDescendence/objectiveDescendence';
import SelectUsers from '../../../modules/user/component/selectUsers/selectUsers.component';
import ObjectiveDTO from '../../../modules/objective/dtos/objective.dto';
import ObjectiveRepository from '../../../modules/objective/objective.repository';
import { RingLoader } from 'react-spinners';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditObjective = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [objective, setObjective] = useState<ObjectiveDTO | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const getObjective = async () => {
        setLoading(true);
        if (params.id) {
            const objectiveResult = await ObjectiveRepository.findOne(params.id, { relations: ['manager', 'categories'] })
            setObjective(objectiveResult)
        }
        setLoading(false);
    }

    useEffect(() => {
        getObjective()
    }, [])

    const initialValuesForm: CreateObjectiveDTO = {
        name: objective?.name || '',
        description: objective?.description || '',
        categories: objective?.categories || [],
        manager: objective?.manager || null,
        startDate: objective?.startDate || new Date(),
        endDate: objective?.endDate || new Date(),
        points: objective?.points || 0,
        visibility: ObjectiveVisibilityEnum.PUBLIC,
        notify: objective?.notify || [],
        descendant: objective?.descendant || null
    }

    const renderLoading = () => {
        return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <RingLoader color='#384FF0' size={35} />
        </div>
    }


    const handlerSubmitForm = async (objective: any) => {
        await ObjectiveRepository.update(objective?.id || 0, objective as ObjectiveDTO)
        navigate(`/objective/detail/${objective?.id}`);
    }

    return <div className='container mx-auto text-foreground'>
        <h2 className='text-primary font-paytoneOne gap-3 flex items-center'>
            <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => navigate(-1)} />
            {t('objective.edit')}
        </h2>
        <p>{t('objective.editLabel')}</p>
        {
            isLoading ? renderLoading() :
                <Formik
                    initialValues={initialValuesForm}
                    validationSchema={createObjectiveValidation()}
                    onSubmit={(values, actions) => {
                        handlerSubmitForm({
                            ...values,
                            points: Number(values.points),
                            startDate: values.startDate,
                            endDate: new Date(values.endDate),
                            id: objective?.id
                        });
                    }}
                >
                    {({ values, errors, setFieldValue, touched, isValid }: {
                        values: CreateObjectiveDTO,
                        errors: FormikErrors<CreateObjectiveDTO>,
                        setFieldValue: (field: string, value: any) => void,
                        touched: FormikTouched<CreateObjectiveDTO>,
                        isValid: boolean
                    }): ReactNode => {
                        return <Form className='flex flex-col gap-5 py-3'>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <NormalInput
                                    id='name'
                                    label={t('objective.inputs.name.label')}
                                    required={true}
                                    placeholder='Ejemplo: Aumentar las ventas'
                                    error={errors.name}
                                    touched={touched.name}
                                />
                                <SelectGoalCategory
                                    containerClassName=''
                                    values={values.categories}
                                />
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <InputTextArea
                                    id='description'
                                    label={t('objective.inputs.description.label')}
                                />
                                <InputTimeFrame
                                    id='Date'
                                    label={t('objective.inputs.period.label')}
                                    required={true}
                                    errors={{
                                        startDate: errors.startDate as string,
                                        endDate: errors.endDate as string
                                    }}
                                    toucheds={{
                                        startDate: touched.startDate as boolean,
                                        endDate: touched.endDate as boolean
                                    }}
                                    onSelectQuarter={(q) => {
                                        const dateFrom = new Date();
                                        const dateTo = new Date();
                                        if (q === 'Q1') {
                                            dateFrom.setDate(1)
                                            dateFrom.setMonth(0);
                                            dateTo.setDate(1);
                                            dateTo.setMonth(3);
                                            dateTo.setDate(0)

                                            setFieldValue('startDate', dateFrom)
                                            setFieldValue('endDate', dateTo)
                                        }
                                        if (q === 'Q2') {
                                            dateFrom.setDate(1)
                                            dateFrom.setMonth(3);
                                            dateTo.setDate(1);
                                            dateTo.setMonth(6);
                                            dateTo.setDate(0);

                                            setFieldValue('startDate', dateFrom)
                                            setFieldValue('endDate', dateTo)
                                        }
                                        if (q === 'Q3') {
                                            dateFrom.setDate(1)
                                            dateFrom.setMonth(6);
                                            dateTo.setDate(1);
                                            dateTo.setMonth(9);
                                            dateTo.setDate(0);

                                            setFieldValue('startDate', dateFrom)
                                            setFieldValue('endDate', dateTo)
                                        }
                                        if (q === 'Q4') {
                                            dateFrom.setDate(1)
                                            dateFrom.setMonth(9);
                                            dateTo.setDate(1);
                                            dateTo.setMonth(12);
                                            dateTo.setDate(0);

                                            setFieldValue('startDate', dateFrom)
                                            setFieldValue('endDate', dateTo)
                                        }
                                    }}
                                />
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <ObjectiveDescendence
                                    id='descendant'
                                    label={t('objective.inputs.descendant.label')}
                                    subLabel={t('objective.inputs.descendant.helper')}
                                    className={''}
                                    value={values.descendant}
                                    touched={touched.descendant}
                                />
                                <SelectUser
                                    id='manager'
                                    label={t('objective.inputs.manager.label')}
                                    subLabel={t('objective.inputs.manager.helper')}
                                    required={true}
                                    containerClassName={''}
                                    values={values.manager}
                                    error={errors.manager}
                                    touched={touched.manager}
                                />
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <div>
                                    <NormalInput
                                        id='points'
                                        label={t('objective.inputs.points.label')}
                                        required={true}
                                        subLabel={t('objective.inputs.points.helper')}
                                        icon={faRocket}
                                        error={errors.points}
                                    />
                                    <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
                                        <Button color='success' variant='flat' onClick={() => setFieldValue('points', 10)}>{t('objective.inputs.points.options.easy')} 10</Button>
                                        <Button color='warning' variant='flat' onClick={() => setFieldValue('points', 15)}>{t('objective.inputs.points.options.moderate')} 15</Button>
                                        <Button color='danger' variant='flat' onClick={() => setFieldValue('points', 30)}>{t('objective.inputs.points.options.hard')} 30</Button>
                                    </div>
                                </div>
                                <SelectUsers
                                    id='notify'
                                    label={t('objective.inputs.notify.label')}
                                    subLabel={t('objective.inputs.notify.helper')}
                                    containerClassName={''}
                                    values={values.notify}
                                />

                            </div>
                            <Button color='primary' isDisabled={!isValid} type='submit' className='mt-5'>{t('objective.button.continue')}</Button>
                        </Form>
                    }}
                </Formik>
        }
    </div>
}

export default EditObjective;
