import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Input } from "@nextui-org/react";

export interface IInput {
    id: string;
    placeholder?: string;
    required?: boolean;
    type?: 'input' | 'number';
    icon?: IconDefinition;
    className?: string;
    label?: string;
    subLabel?: string;
    error?: string;
    touched?: boolean;
}

const NormalInput = ({ id, placeholder, required, type = 'input', icon, className, label, subLabel, error, touched }: IInput) => {
    return <Field as={type} id={id} name={id}>
        {({ field }: { field: any }) => (
            <Input color={(error) && 'danger'} type={type} label={label} description={subLabel} startContent={icon && <FontAwesomeIcon icon={icon} />} isInvalid={(error) ? true : false} errorMessage={(error) && error} {...field} />
        )}
    </Field>
}

export default NormalInput;