import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { RingLoader } from 'react-spinners';
import DateUtil from '../../../utils/date.util';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import KeyResultDTO from '../../../modules/keyResult/dto/keyResult.dto';
import KeyResultRepository from '../../../modules/keyResult/keyResult.repository';
import StatusEnum from '../../../modules/keyResult/enum/status.enum';
import StatusInput from '../../../components/Input/StatusInput/statusInput';
import { Field, FieldProps, Formik } from 'formik';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Avatar, AvatarGroup, Button, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tab, Tabs, Textarea, Tooltip, User } from '@nextui-org/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const KeyResultDetail = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [isDeleteModalActive, setDeleteModalActive] = useState(false);
    const { t } = useTranslation();
    const profile = useSelector((state: RootState) => state.profile);
    const [keyResult, setKeyResult] = useState<KeyResultDTO | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false);

    const getKeyResult = async () => {
        setLoading(true);
        if (params.id) {
            const keyResultResult = await KeyResultRepository.findOne(params.id, { relations: ['manager', 'colaborators', 'history', 'comments', 'comments.user', 'initiatives'] })
            setKeyResult(keyResultResult)
        }
        setLoading(false);
    }

    useEffect(() => {
        getKeyResult()
    }, [])
    const renderLoading = () => {
        return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <RingLoader color='#384FF0' size={35} />
        </div>
    }

    const handlerDelete = async () => {
        if (keyResult) {
            await KeyResultRepository.remove(keyResult.id);
            navigate('/objective');
        }
    }

    const translateFields = (field: string) => {
        switch (field) {
            case 'name':
                return 'Nombre';
            case 'description':
                return 'Descripcion';
            case 'colaborators':
                return 'Colaboladores';
            case 'notify':
                return 'Notificaciones';
            case 'startDate':
                return 'Fecha Inicio';
            case 'endDate':
                return 'Fecha Fin';
            case 'status':
                return 'Estado';
            case 'meditionType':
                return 'Tipo de medicion';
            case 'meditionValue':
                return 'Valor de la medicion';
            case 'meditionIntendedValue':
                return 'Valor esperado';
            default: return 'UNKNOW'
        }
    }

    const getCssStatus = (status: 'PENDING' | 'IN_PROGRESS' | 'COMPLETED') => {
        const statusCss = {
            PENDING: 'text-center font-bold bg-black-200 text-black-800 border border border-black-600 rounded p-3 text-sm',
            COMPLETED: 'text-center font-bold bg-green-200 text-green-800 border border border-green-600 rounded p-3 text-sm',
            IN_PROGRESS: 'text-center font-bold bg-primary-200 text-primary-800 border border border-primary-600 rounded p-3 text-sm'
        }

        return statusCss[status];
    }

    const [commentMessage, setCommentMessage] = useState("");

    const handlerComment = async () => {
        await axios.post(`/keyResult/${keyResult?.id}/comment`, {
            content: commentMessage
        });
        setCommentMessage('');
        getKeyResult()
    }

    return <div className='container mx-auto text-foreground'>
        <h2 className='text-primary font-paytoneOne gap-3 flex items-center'>
            <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => navigate(-1)} />
            {t('keyResult.detail')}
        </h2>
        {
            isLoading ? renderLoading() :
                <div>
                    <Modal isOpen={isDeleteModalActive} onClose={() => setDeleteModalActive(!isDeleteModalActive)}>
                        <ModalContent>
                            <ModalHeader >
                                {t('keyResult.modal.delete.header')}
                            </ModalHeader>
                            <ModalBody>
                                <p>{t('keyResult.modal.delete.body')} <span className='font-semibold'>{keyResult?.name}</span></p>
                                <p className='text-center pt-5'>{t('keyResult.modal.delete.sure')}</p>
                            </ModalBody>
                            <ModalFooter className='justify-between'>
                                <Button color='primary' variant='light' onClick={() => setDeleteModalActive(!isDeleteModalActive)}>{t('keyResult.modal.delete.back')}</Button>
                                <Button color='danger' variant='flat' onClick={() => handlerDelete()}>{t('keyResult.modal.delete.delete')}</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <div className='flex gap-3'>
                        <div className='bg-primary rounded px-3 py-3 text-white flex items-center justify-center'>
                            KR
                        </div>
                        <div>
                            <p className='leading-3 text-xs font-bold'>{t('keyResult.title')}</p>
                            <p className='leading-3'>{keyResult?.name}</p>
                        </div>
                        <div className='flex items-center gap-3 pl-3'>
                            <Button variant='flat' color='primary' onClick={() => navigate(`/keyResult/edit/${keyResult?.id}`)}><FontAwesomeIcon icon={faPencil} /></Button>
                            <Button variant='flat' color='danger' onClick={() => setDeleteModalActive(!isDeleteModalActive)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </div>
                    </div>
                    <div>
                        <p><b>{t('keyResult.description')}:</b> {keyResult?.description || '-'}</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <div className='flex flex-col items-start'>
                            <p className='font-bold'>{t('keyResult.period')}</p>
                            <div className="bg-primary text-white text-xs py-2 px-3 rounded">
                                {DateUtil.formatDateLabel(keyResult?.startDate || new Date())} - {DateUtil.formatDateLabel(keyResult?.endDate || new Date())}
                            </div>
                        </div>
                        <div className='flex flex-col items-start'>
                            <p className='font-bold'>{t('keyResult.manager')}</p>
                            <User name={`${keyResult?.manager.firstName} ${keyResult?.manager.lastName}`} avatarProps={{
                                src: keyResult?.manager.image,
                                color: 'primary',
                                isBordered: true
                            }} />
                        </div>
                        <div className='flex flex-col items-start'>
                            <p className='font-bold'>{t('keyResult.status')}</p>
                            <div className='flex items-center'>
                                <Formik initialValues={{ status: keyResult?.status || StatusEnum.IN_TIME }} onSubmit={(values, actions) => {
                                    KeyResultRepository.update(keyResult?.id || 0, { status: values.status })
                                }}>
                                    {({ values, handleSubmit }) => {
                                        return <StatusInput id='status' label='Estado' value={values.status} onSelect={() => handleSubmit()} />
                                    }}

                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className='pt-5 grid grid-cols-3'>
                        <div className='flex flex-col items-start'>
                            <p className='font-bold'>{t('keyResult.estimatedValue')}</p>
                            <div>
                                <Formik initialValues={{ meditionIntendedValue: Number(keyResult?.meditionIntendedValue) }} onSubmit={(values) => {
                                    KeyResultRepository.update(keyResult?.id || 0, { meditionIntendedValue: values.meditionIntendedValue })
                                }}>
                                    {({ values, handleSubmit }) => {
                                        return <Field id='meditionIntendedValue' name='meditionIntendedValue'>
                                            {({ form }: FieldProps) => {
                                                return <Input type='number' value={values.meditionIntendedValue?.toString()} onChange={(e) => {
                                                    form.setFieldValue('meditionIntendedValue', isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber);
                                                    handleSubmit()
                                                }} />
                                            }}
                                        </Field>
                                    }}

                                </Formik>
                            </div>
                        </div>
                        <div className='flex flex-col items-start'>
                            <p className='font-bold'>{t('keyResult.actualValue')}</p>
                            <div>
                                <Formik initialValues={{ meditionValue: Number(keyResult?.meditionValue) }} onSubmit={(values) => {
                                    KeyResultRepository.update(keyResult?.id || 0, { meditionValue: values.meditionValue })
                                }}>
                                    {({ values, handleSubmit }) => {
                                        return <Field id='meditionValue' name='meditionValue'>
                                            {({ form }: FieldProps) => {
                                                return <Input type='number' value={values.meditionValue?.toString()} onChange={(e) => {
                                                    form.setFieldValue('meditionValue', isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber);
                                                    handleSubmit()
                                                }} />
                                            }}
                                        </Field>
                                    }}

                                </Formik>
                            </div>
                        </div>
                        <div className='flex flex-col items-start justify-center'>
                            <div style={{ width: 75, height: 75 }}>
                                <CircularProgressbar value={(isNaN(Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) ? 100 : (Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) * 100)} text={`${((isNaN(Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) ? 100 : (Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) * 100)).toFixed(0)}%`} styles={buildStyles({
                                    pathColor: `#24B500`,
                                    textColor: '#24B500',
                                })} />
                            </div>
                        </div>
                    </div>
                    <div className='pt-5'>
                        <p className='font-bold'>{t('keyResult.colaborators')}</p>

                        <AvatarGroup isBordered isGrid color='primary'>
                            {keyResult?.colaborators.map(colaborator => {
                                return <Tooltip color='primary' content={`${colaborator.firstName} ${colaborator.lastName}`}>
                                    <Avatar className='cursor-pointer' name={`${colaborator.firstName} ${colaborator.lastName}`} src={colaborator.image ? `https://giveit-system-assets.s3.amazonaws.com/${colaborator.image}` : ''} />
                                </Tooltip>
                            })}
                        </AvatarGroup>
                    </div>
                    <div className='pt-10'>
                        <Tabs>
                            <Tab key='initiative' title={t('keyResult.initatives')}>
                                <div className='flex justify-end'>
                                    <Button color='primary' onClick={() => navigate(`/initiative/${keyResult?.id}`)}>{t('initiative.create')}</Button>
                                </div>
                                <div className='py-3'>
                                    {keyResult?.initiatives.map(initiative => <Card>
                                        <CardBody >
                                            <div className='grid grid-cols-2 items-center'>
                                                <a href={`/initiative/edit/${initiative.id}`}>{initiative.name}</a>
                                                <div>
                                                    <Formik initialValues={{ status: initiative.status || 'PENDING' }} onSubmit={(values, actions) => {
                                                        const initiativeToUpdate = initiative;
                                                        initiativeToUpdate.status = values.status;
                                                        axios.put(`/initiative/${initiative.id}`, initiativeToUpdate);
                                                    }}>
                                                        {({ values, handleSubmit }) => {
                                                            return <Field id={'status'} name={'status'}>
                                                                {({ form }: FieldProps) => {

                                                                    const select = (value: 'PENDING' | 'IN_PROGRESS' | 'COMPLETED') => {
                                                                        form.setFieldValue('status', value);
                                                                        handleSubmit();
                                                                    }

                                                                    return <Dropdown>
                                                                        <DropdownTrigger className="cursor-pointer">
                                                                            <div className={getCssStatus(values.status)}>{t(`initiative.statuses.${values.status}`)}</div>
                                                                        </DropdownTrigger>
                                                                        <DropdownMenu>
                                                                            <DropdownItem>
                                                                                <div className={getCssStatus('PENDING')} onClick={() => {
                                                                                    select('PENDING');
                                                                                }}>{t(`initiative.statuses.${'PENDING'}`)}</div>
                                                                            </DropdownItem>
                                                                            <DropdownItem>
                                                                                <div className={getCssStatus('IN_PROGRESS')} onClick={() => {
                                                                                    select('IN_PROGRESS');
                                                                                }}>{t(`initiative.statuses.${'IN_PROGRESS'}`)}</div>
                                                                            </DropdownItem>
                                                                            <DropdownItem>
                                                                                <div className={getCssStatus('COMPLETED')} onClick={() => {
                                                                                    select('COMPLETED');
                                                                                }}>{t(`initiative.statuses.${'COMPLETED'}`)}</div>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                }}
                                                            </Field>
                                                        }}

                                                    </Formik>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>)}
                                </div>
                            </Tab>
                            <Tab key='comment' title={t('keyResult.comments.title')}>
                                <div>
                                    <div className="flex gap-3">
                                        <Avatar className='mt-3' src={`https://giveit-system-assets.s3.amazonaws.com/${profile.image || 'avatar_giveit.webp'}`} />
                                        <Textarea
                                            placeholder={t('keyResult.comments.placeholder')}
                                            value={commentMessage}
                                            onChange={(e) => setCommentMessage(e.target.value)}
                                        />
                                    </div>
                                    <div className={`pt-3 flex justify-end ${commentMessage === '' && 'hidden'}`}>
                                        <Button color='primary' onClick={() => handlerComment()}>{t('objective.comments.button')}</Button>
                                    </div>
                                </div>
                                {
                                    keyResult?.comments.reverse().map((comment) => (
                                        <Card className="ps-2 mt-3">
                                            <CardBody>
                                                <div className="flex gap-3 items-center">
                                                    <Avatar src={`https://giveit-system-assets.s3.amazonaws.com/${comment.user.image || 'avatar_giveit.webp'}`} />
                                                    <p>{comment.user.firstName} {comment.user.lastName}</p>

                                                </div>
                                                <p className='px-3 text-sm'>
                                                    {comment.content}
                                                </p>
                                                <div style={{ marginTop: 5, fontSize: 12, color: "#636363", textAlign: 'end' }}>
                                                    {new Date(comment.createdDate).getDate()}/
                                                    {(new Date(comment.createdDate).getMonth() + 1)
                                                        .toString()
                                                        .padStart(2, "0")}
                                                    /{new Date(comment.createdDate).getFullYear()}{" "}
                                                    {new Date(comment.createdDate).getHours().toString().padStart(2, "0")}:
                                                    {new Date(comment.createdDate).getMinutes().toString().padStart(2, "0")}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ))
                                }
                            </Tab>
                            <Tab key='history' title={t('keyResult.history.title')}>
                                <ul className=''>
                                    {keyResult?.history.reverse().map(historyItem => {
                                        return <li className='py-1'>
                                            <p>
                                                {DateUtil.formatDDMMAAAA(new Date(historyItem.createdDate))}
                                                <span className={`text-white rounded text-sm p-2 mx-3 ${historyItem.action === 'CREATED' ? 'bg-success' : 'bg-warning'}`}>{t(`keyResult.history.actions.${historyItem.action}`)}</span>
                                                <strong>{historyItem.createdBy}</strong> {t(`keyResult.history.actionLabel.${historyItem.action}`, { field: t(`keyResult.inputs.${historyItem.field}.label`), prevValue: historyItem.prevValue || t('empty'), currentValue: historyItem.currentValue || t('empty') })}
                                            </p>
                                        </li>
                                    })}
                                </ul>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
        }

    </div>
}

export default KeyResultDetail;