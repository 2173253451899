import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputSelection from "../../../../components/Input/InputSelection/InputSelection"
import UserDTO from "../../dto/user.dto"
import UserRepository from "../../user.repository";

import styles from './selectUser.module.css';
import AvatarSelectionTemplate from "../userAvatarSelectionItem/userAvatarSelectionItem";
import AvatarSelectedTemplate from "../userAvatarSelectedItem/userAvatarSelectedItem";
import { Listbox, ListboxItem, User } from "@nextui-org/react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
//import { ListGroup } from "react-bootstrap";

interface ISelectUser {
    id: string;
    label: string;
    containerClassName: string;
    values: UserDTO | null;
    required?: boolean;
    error?: string;
    touched?: boolean;
    subLabel?: string;
}

const SelectUser = (props: ISelectUser) => {
    return <InputSelection<UserDTO>
        id={props.id}
        label={props.label}
        placeholder=''
        handlerFindOptions={async (finder) => {
            return await UserRepository.find({ where: [{ email: `lk=${finder}` }, { firstName: `lk=${finder}` }, { lastName: `lk=${finder}` }] })
        }}
        optionItemTemplate={() => { return null }}
        optionContainerTemplate={(options, select, clear) => {
            if (options.length === 0) return null;
            return <div className="relative">
                <Listbox className="border rounded absolute bg-background z-20">
                    {options.map(option => <ListboxItem key={option.id.toString()} onClick={() => {
                        select(option);
                        clear();
                    }}>
                        <User name={`${option.firstName} ${option.lastName}`} avatarProps={{ src: option.image ? `https://giveit-system-assets.s3.amazonaws.com/${option.image}` : 'https://giveit-system-assets.s3.amazonaws.com/avatar_giveit.webp' }} />
                    </ListboxItem>)}
                </Listbox>
            </div>
        }}
        itemContainerClassName={styles.valuesContainer}
        valueItemTemplate={(value, remove, clear) => {
            return <div className="border mt-2 rounded p-2 flex items-center justify-between">
                <User name={`${value.firstName} ${value.lastName}`} avatarProps={{ src: value.image ? `https://giveit-system-assets.s3.amazonaws.com/${value.image}` : 'https://giveit-system-assets.s3.amazonaws.com/avatar_giveit.webp' }} />
                <FontAwesomeIcon icon={faTimes} onClick={() => remove()} />
            </div>
        }}
        value={props.values}
        required={props.required}
        error={props.error}
        touched={props.touched}
        subLabel={props.subLabel}
    />
}

export default SelectUser;