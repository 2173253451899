import { useNavigate, useParams } from 'react-router-dom';
import styles from './detail.module.css';
import { useEffect, useState } from 'react';
import ObjectiveRepository from '../../../modules/objective/objective.repository';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faUser, faKey, faPencil, faTrash, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ObjectiveDTO from '../../../modules/objective/dtos/objective.dto';
import { RingLoader } from 'react-spinners';
import DateUtil from '../../../utils/date.util';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import KeyResultItem from './components/keyResultItem/keyResultItem';
import KeyResultDTO from '../../../modules/keyResult/dto/keyResult.dto';
import StatusEnum from '../../../modules/keyResult/enum/status.enum';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Card, CardBody, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tab, Tabs, Textarea, User } from '@nextui-org/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const ObjectiveDetail = () => {
    const params = useParams();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [isDeleteModalActive, setDeleteModalActive] = useState(false);
    const [activeTab, setActiveTab] = useState<'comment' | 'history'>('comment');
    const profile = useSelector((state: RootState) => state.profile);
    const [objective, setObjective] = useState<ObjectiveDTO | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false);

    const getObjective = async () => {
        setLoading(true);
        if (params.id) {
            const objectiveResult = await ObjectiveRepository.findOne(params.id, { relations: ['keyResults', 'manager', 'comments', 'comments.user'] })
            setObjective(objectiveResult)
        }
        setLoading(false);
    }

    useEffect(() => {
        getObjective()
    }, [])

    const renderLoading = () => {
        return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <RingLoader color='#384FF0' size={35} />
        </div>
    }

    const calculatePercent = (keyResults: Array<KeyResultDTO>) => {
        let percent = 0;
        keyResults.map(keyResult => {
            percent += (isNaN(Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) ? 100 : (Number(keyResult?.meditionValue) / Number(keyResult?.meditionIntendedValue)) * 100)
        })
        percent = percent / keyResults.length;
        return percent;
    }

    const calculateState = (keyResults: Array<KeyResultDTO>) => {
        let percent = 0;
        keyResults.map(keyResult => {
            if (keyResult.status === StatusEnum.IN_TIME)
                percent += 100;
            if (keyResult.status === StatusEnum.LATE)
                percent += 50;
            if (keyResult.status === StatusEnum.AT_RISK)
                percent += 0;
        })
        percent = percent / keyResults.length;

        if (percent > 75)
            return StatusEnum.IN_TIME;
        if (percent >= 50)
            return StatusEnum.LATE;

        return StatusEnum.AT_RISK;
    }

    const getCssStatus = (status: StatusEnum) => {
        const statusCss = {
            AT_RISK: 'bg-red-200 text-red-800 border border border-red-600 rounded p-3 text-sm',
            IN_TIME: 'bg-green-200 text-green-800 border border border-green-600 rounded p-3 text-sm',
            LATE: 'bg-yellow-200 text-yellow-800 border border border-yellow-600 rounded p-3 text-sm'
        }

        return statusCss[status];
    }

    const handlerDelete = async () => {
        if (objective) {
            await ObjectiveRepository.remove(objective.id);
            navigate('/objective');
        }
    }

    const [commentMessage, setCommentMessage] = useState("");

    const handlerComment = async () => {
        await axios.post(`/objective/${objective?.id}/comment`, {
            content: commentMessage
        });
        setCommentMessage('');
        getObjective()
    }

    return <div className="container-fluid pt-3 text-foreground">
        <div className="row">
            <h2 className='text-primary font-paytoneOne gap-3 flex items-center'>
                <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => navigate(-1)} />
                {t('objective.detail')}
            </h2>
        </div>
        {
            isLoading ? renderLoading() :
                <div>
                    <Modal isOpen={isDeleteModalActive} onClose={() => setDeleteModalActive(!isDeleteModalActive)}>
                        <ModalContent>
                            <ModalHeader >
                                {t('objective.modal.delete.header')}
                            </ModalHeader>
                            <ModalBody>
                                <p>{t('objective.modal.delete.body')} <span className='font-semibold'>{objective?.name}</span></p>
                                <p className='text-center pt-5'>{t('objective.modal.delete.sure')}</p>
                            </ModalBody>
                            <ModalFooter className='justify-between'>
                                <Button color='primary' variant='light' onClick={() => setDeleteModalActive(!isDeleteModalActive)}>{t('objective.modal.delete.back')}</Button>
                                <Button color='danger' variant='flat' onClick={() => handlerDelete()}>{t('objective.modal.delete.delete')}</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <div className="flex items-center gap-3">
                        <FontAwesomeIcon icon={faBullseye} className='text-primary' />
                        <p><b>{t('objective.title')}:</b> {objective?.name}</p>
                        <div className='flex gap-3'>
                            <Button variant='flat' color='primary' onClick={() => navigate(`/objective/edit/${objective?.id}`)}><FontAwesomeIcon icon={faPencil} /></Button>
                            <Button variant='flat' color='danger' onClick={() => setDeleteModalActive(!isDeleteModalActive)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </div>
                    </div>
                    <div>
                        <p><b>{t('objective.description')}:</b> {objective?.description || '-'}</p>
                    </div>
                    <div className='grid grid-cols-4'>
                        <div className='flex flex-col items-start'>
                            <p className='font-bold'>{t('objective.period')}</p>
                            <div className="bg-primary text-white text-xs py-2 px-3 rounded">
                                {DateUtil.formatDateLabel(objective?.startDate || new Date())} - {DateUtil.formatDateLabel(objective?.endDate || new Date())}
                            </div>
                        </div>
                        <div className='flex flex-col items-start'>
                            <p className='font-bold'>{t('objective.manager')}</p>
                            <User name={`${objective?.manager.firstName} ${objective?.manager.lastName}`} avatarProps={{
                                src: objective?.manager.image
                            }} />
                        </div>
                        <div className='flex flex-col items-start'>
                            <p className='font-bold'>{t('objective.status')}</p>
                            <div className={getCssStatus(calculateState(objective?.keyResults || []))}>{t(`objective.statuses.${calculateState(objective?.keyResults || [])}`)}</div>
                        </div>
                        <div>
                            <p className='font-bold'>{t('objective.progress')}</p>
                            <div style={{ width: 50, height: 50 }}>
                                <CircularProgressbar value={calculatePercent(objective?.keyResults || [])} text={`${calculatePercent(objective?.keyResults || []).toFixed(0)}%`} styles={buildStyles({
                                    pathColor: `#24B500`,
                                    textColor: '#24B500',
                                })} />
                            </div>
                        </div>
                    </div>
                    <div className='pt-10'>
                        <div className='flex items-center gap-3'>
                            <FontAwesomeIcon icon={faKey} />
                            <div className='font-bold'> {t('objective.keyResults')}: (KR) </div>
                        </div>
                        <div className='flex flex-col gap-3'>
                            {objective?.keyResults.map(keyResult => <KeyResultItem keyResult={keyResult} />)}
                        </div>
                    </div>
                    <div className='pt-10'>
                        <Tabs>
                            <Tab key='comment' title={t('objective.comments.title')}>
                                <div>
                                    <div className="flex gap-3">
                                        <Avatar className='mt-3' src={`https://giveit-system-assets.s3.amazonaws.com/${profile.image || 'avatar_giveit.webp'}`} />
                                        <Textarea
                                            placeholder={t('objective.comments.placeholder')}
                                            value={commentMessage}
                                            onChange={(e) => setCommentMessage(e.target.value)}
                                        />
                                    </div>
                                    <div className={`pt-3 flex justify-end ${commentMessage === '' && 'hidden'}`}>
                                        <Button color='primary' onClick={() => handlerComment()}>{t('objective.comments.button')}</Button>
                                    </div>
                                </div>
                                {
                                    objective?.comments.map((comment) => (
                                        <Card className="ps-2 mt-3">
                                            <CardBody>
                                                <div className="flex gap-3 items-center">
                                                    <Avatar src={`https://giveit-system-assets.s3.amazonaws.com/${comment.user.image || 'avatar_giveit.webp'}`} />
                                                    <p>{comment.user.firstName} {comment.user.lastName}</p>

                                                </div>
                                                <p className='px-3 text-sm'>
                                                    {comment.content}
                                                </p>
                                                <div style={{ marginTop: 5, fontSize: 12, color: "#636363", textAlign: 'end' }}>
                                                    {new Date(comment.createdDate).getDate()}/
                                                    {(new Date(comment.createdDate).getMonth() + 1)
                                                        .toString()
                                                        .padStart(2, "0")}
                                                    /{new Date(comment.createdDate).getFullYear()}{" "}
                                                    {new Date(comment.createdDate).getHours().toString().padStart(2, "0")}:
                                                    {new Date(comment.createdDate).getMinutes().toString().padStart(2, "0")}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ))
                                }
                            </Tab>
                        </Tabs>
                    </div>
                </div>
        }
    </div>
}

export default ObjectiveDetail;