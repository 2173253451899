import styles from './objectiveDescendence.module.css';
import InputSelection from '../../../../components/Input/InputSelection/InputSelection';
import ObjectiveDTO from '../../dtos/objective.dto';
import ObjectiveRepository from '../../objective.repository';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import KeyResultDTO from '../../../keyResult/dto/keyResult.dto';
import { Accordion, AccordionItem, Card, CardBody } from '@nextui-org/react';
//import { Accordion, Card, ListGroup } from 'react-bootstrap';


interface IObjectiveDescendence {
    id: string;
    label: string;
    value: KeyResultDTO | null;
    subLabel: string;
    className?: string;
    touched?: boolean;
}

const KeyResultItem = ({ keyResult, onSelect }: { keyResult: KeyResultDTO, onSelect: (value: KeyResultDTO) => void }) => {
    return <div className='flex items-center gap-3 hover:bg-gray-100 rounded' onClick={() => onSelect(keyResult)}>
        <div className='bg-primary text-primary-foreground p-2 rounded'>KR</div>
        <div className=''>{keyResult.name}</div>
    </div>
}

const ObjectiveDescendence = (props: IObjectiveDescendence) => {
    const handlerFindOptions = async (finder: string): Promise<ObjectiveDTO[]> => {
        const objectives = await ObjectiveRepository.find({ where: { name: `lk=${finder}` }, relations: ['keyResults'] })
        return objectives;
    }

    const optionTemplate = (objective: ObjectiveDTO, select: (value: KeyResultDTO) => void, clear: () => void) => {
        return <AccordionItem key={objective.id.toString()} title={
            <div className='flex gap-3 items-center'>
                <FontAwesomeIcon icon={faBullseye} color='#384FF0' />
                <span>{objective.name}</span>
            </div>
        }>
            {objective.keyResults.map(keyResult => <KeyResultItem keyResult={keyResult} onSelect={(value) => {
                select(value);
                clear();
            }} />)}
        </AccordionItem>
    }

    const optionContainerTemplate = (objectives: ObjectiveDTO[], select: (value: KeyResultDTO) => void, clear: () => void) => {
        if (objectives.length === 0) return null;

        return <div className='relative z-10'>
            <Accordion isCompact variant='shadow' className='absolute overflow-auto max-h-72'>
                {objectives.map(objective => optionTemplate(objective, select, clear))}
            </Accordion>
        </div>
    }



    const valueTemplate = (keyResult: KeyResultDTO, remove: () => void) => {
        return <div className='mt-2 flex border p-2 rounded items-center justify-between'>
            <div className='flex items-center gap-3'>
                <div className='bg-primary rounded text-primary-foreground px-3 py-2'>KR</div>
                <div className=''>{keyResult.name}</div>
            </div>
            <FontAwesomeIcon icon={faTimes} onClick={() => remove()} />
        </div>
    }

    return <div className={props.className}>
        <InputSelection<ObjectiveDTO> touched={props.touched} id={props.id} label={props.label} subLabel={props.subLabel} handlerFindOptions={handlerFindOptions} optionContainerTemplate={optionContainerTemplate} optionItemTemplate={optionTemplate} valueItemTemplate={valueTemplate} value={props.value} optionsContainerClassName={styles.optionContainer} />
    </div>
}

export default ObjectiveDescendence;