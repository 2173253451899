import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react"
import { Form, Formik } from "formik"
import { useState } from "react";
import { useTranslation } from "react-i18next"
import SelectGoalCategory from "../../../../../modules/goalCategory/components/selectGoalCategory/selectGoalCategory.component";
import SelectUsers from "../../../../../modules/user/component/selectUsers/selectUsers.component";
import InputTimeFrame from "../../../../../components/Input/InputTimeFrame/InputTimeFrame";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Filter = ({ onFilter }: { onFilter: (options: string[]) => void }) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<Array<'category' | 'manager' | 'period'>>([]);

    const addFilter = (filterName: 'category' | 'manager' | 'period') => {
        const filterCopy = [...filters];
        if (filterCopy.findIndex(filter => filter === filterName))
            filterCopy.push(filterName);

        setFilters(filterCopy);
    }

    const deleteFilter = (index: number) => {
        const filterCopy = [...filters];
        filterCopy.splice(index, 1);
        setFilters(filterCopy);
    }

    return <div className="py-3">
        <Formik
            initialValues={{} as any}
            onSubmit={(values) => {
                let options = {} as any;
                if (values.categories && values.categories.length > 0 && filters.some(filter => filter === 'category'))
                    options['categories'] = { id: `in=${values.categories.map((category: any) => category.id).join(',')}` };
                if (values.manager && values.manager.length > 0 && filters.some(filter => filter === 'manager'))
                    options['manager'] = { id: `in=${values.manager.map((manager: any) => manager.id).join(',')}` };
                if (values.startDate && values.endDate && filters.some(filter => filter === 'period'))
                    options['startDate'] = `bt=${values.startDate},${values.endDate}`;

                onFilter(options);
            }}>
            {({ values, setFieldValue }: { values: any, setFieldValue: (field: string, value: any) => void }) => {
                return <Form className="flex flex-col gap-3">
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger>
                                <Button className="bg-white border">{t('filter.addFilter')}</Button>
                            </DropdownTrigger>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { addFilter('category') }}>{t('objective.inputs.category.label')}</DropdownItem>
                                <DropdownItem onClick={() => { addFilter('manager') }}>{t('objective.inputs.manager.label')}</DropdownItem>
                                <DropdownItem onClick={() => { addFilter('period') }}>{t('objective.inputs.period.label')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Button type="submit" color="primary" variant="flat">Filtrar</Button>
                    </div>

                    {
                        filters.map((filter, index) => {
                            if (filter === 'category')
                                return <div >
                                    <SelectGoalCategory endContent={<FontAwesomeIcon icon={faTimes} className="text-danger" onClick={() => {
                                        deleteFilter(index)
                                    }} />} disableCreate={true} containerClassName="" values={values.categories || []} />
                                </div>

                            if (filter === 'manager')
                                return <div >
                                    <SelectUsers id="manager" label={t('objective.inputs.manager.label')} containerClassName="" values={values.manager || []}
                                        endContent={<FontAwesomeIcon icon={faTimes} className="text-danger" onClick={() => {
                                            deleteFilter(index)
                                        }} />}
                                    />
                                </div>

                            if (filter === 'period')
                                return <div className="border p-3 grid grid-cols-12">
                                    <InputTimeFrame
                                        id='Date'
                                        className="col-span-11"
                                        onSelectQuarter={(q) => {
                                            const dateFrom = new Date();
                                            const dateTo = new Date();
                                            if (q === 'Q1') {
                                                dateFrom.setDate(1)
                                                dateFrom.setMonth(0);
                                                dateTo.setDate(1);
                                                dateTo.setMonth(3);
                                                dateTo.setDate(0)

                                                const fromText = `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
                                                const toText = `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}`

                                                setFieldValue('startDate', fromText)
                                                setFieldValue('endDate', toText)
                                            }
                                            if (q === 'Q2') {
                                                dateFrom.setDate(1)
                                                dateFrom.setMonth(3);
                                                dateTo.setDate(1);
                                                dateTo.setMonth(6);
                                                dateTo.setDate(0);

                                                const fromText = `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
                                                const toText = `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}`

                                                setFieldValue('startDate', fromText)
                                                setFieldValue('endDate', toText)
                                            }
                                            if (q === 'Q3') {
                                                dateFrom.setDate(1)
                                                dateFrom.setMonth(6);
                                                dateTo.setDate(1);
                                                dateTo.setMonth(9);
                                                dateTo.setDate(0);

                                                const fromText = `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
                                                const toText = `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}`


                                                setFieldValue('startDate', fromText)
                                                setFieldValue('endDate', toText)
                                            }
                                            if (q === 'Q4') {
                                                dateFrom.setDate(1)
                                                dateFrom.setMonth(9);
                                                dateTo.setDate(1);
                                                dateTo.setMonth(12);
                                                dateTo.setDate(0);

                                                const fromText = `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString().padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
                                                const toText = `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}`


                                                setFieldValue('startDate', fromText)
                                                setFieldValue('endDate', toText)
                                            }
                                        }}
                                    />
                                    <div className="flex items-center justify-center">
                                        <FontAwesomeIcon icon={faTimes} className="text-danger" onClick={() => {
                                            deleteFilter(index)
                                        }} />
                                    </div>
                                </div>
                        })
                    }
                </Form>
            }
            }
        </Formik>
    </div>
}

export default Filter