import axios from 'axios'
import FindOptionsDTO from '../common/dto/findOptions.dto'
import UserDTO from './dto/user.dto'

namespace UserRepository {
  export const find = async (
    options: FindOptionsDTO<UserDTO>
  ): Promise<UserDTO[]> => {
    try {
      const formatedOptions = encodeURIComponent(JSON.stringify(options))
      return (await axios.get<UserDTO[]>(`/user?query=${formatedOptions}`)).data
    } catch (err) {
      throw Error()
    }
  }
}

export default UserRepository
