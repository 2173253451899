import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import CreateInitiativeValidation from "./editValidation";
import NormalInput from "../../../components/Input/NormalInput/NormalInput";
import InputTextArea from "../../../components/Input/InputTextArea/InputTextArea";
import { Button, Select, SelectItem, Spinner } from "@nextui-org/react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import KeyResultRepository from "../../../modules/keyResult/keyResult.repository";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrash } from "@fortawesome/free-solid-svg-icons";

const EditInitiative = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [initiative, setInitiative] = useState<{ name: string, description: string, status: string } | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    const getInitiative = async () => {
        setLoading(true);
        const initiativeResponse = await axios.get(`/initiative/${params.id}`);
        setInitiative(initiativeResponse.data);
        setLoading(false);
    }

    const deleteInitiative = async () => {
        await axios.delete(`/initiative/${params.id}`);
        navigate(-1);
    }

    useEffect(() => {
        getInitiative();
    }, [])

    return <div className="container mx-auto text-foreground">
        <div className="flex justify-between items-center gap-3">
            <h2 className='text-primary font-paytoneOne gap-3 flex items-center'>
                <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => navigate(-1)} />
                {t('initiative.edit')}
            </h2>
            <Button color="danger" onClick={deleteInitiative}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        </div>
        <p>{t('initiative.subTitle')}</p>
        {isLoading ?
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner></Spinner>
            </div> :
            <Formik
                initialValues={{
                    name: initiative?.name || '',
                    description: initiative?.description || '',
                    status: initiative?.status || 'PENDING'
                }}
                validationSchema={CreateInitiativeValidation()}
                onSubmit={async (values) => {
                    await axios.put(`/initiative/${params.id}`, {
                        ...values
                    });
                    navigate(-1);
                }}
            >
                {({ errors, touched, isValid, values }) => {
                    return <Form className="flex flex-col gap-5 py-3">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                            <NormalInput
                                id='name'
                                label={t('initiative.inputs.name.label')}
                                required={true}
                                error={errors.name}
                                touched={touched.name}
                            />
                            <Field as='select' name={'status'}>
                                {({ field }: { field: any }) => (
                                    <Select label={t('initiative.inputs.status.label')} defaultSelectedKeys={[values.status]} {...field} >
                                        <SelectItem key={'PENDING'} value={'PENDING'}>Pendiente</SelectItem>
                                        <SelectItem key={'IN_PROGRESS'} value={'IN_PROGRESS'}>En progreso</SelectItem>
                                        <SelectItem key={'COMPLETED'} value={'COMPLETED'}>Completo</SelectItem>
                                    </Select>
                                )}
                            </Field>
                        </div>
                        <InputTextArea
                            id='description'
                            label={t('initiative.inputs.description.label')}
                        />
                        <Button color="primary" isDisabled={!isValid} type="submit">{t('initiative.buttons.confirm')}</Button>
                    </Form>
                }}

            </Formik>
        }
    </div>
}

export default EditInitiative;