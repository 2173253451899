import axios from 'axios'
import FindOptionsDTO from '../common/dto/findOptions.dto'
import GoalCategoryDTO from './dto/goalCategory.dto'

namespace GoalCategoryRepository {
  export const find = async (
    options: FindOptionsDTO<GoalCategoryDTO>
  ): Promise<GoalCategoryDTO[]> => {
    try {
      const formatedOptions = encodeURIComponent(JSON.stringify(options))
      return (
        await axios.get<GoalCategoryDTO[]>(
          `/goalCategory?query=${formatedOptions}`
        )
      ).data
    } catch (err) {
      throw Error()
    }
  }
}

export default GoalCategoryRepository
