namespace DateUtil {
    export const formatDDMMAAAA = (date: Date) => {
        const days = date.getDate().toString().padStart(2, '0');
        const month = date.getMonth().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${days}/${month}/${year}`;
    }

    export const formatDateLabel = (date: Date) => {
        const days = date.getDate().toString().padStart(2, '0');
        const month = date.getMonth();
        const monthLabel = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

        const year = date.getFullYear();

        return `${days} ${monthLabel[month]} ${year}`;
    }
}

export default DateUtil;