import { useEffect, useState } from 'react';
import ObjectiveDTO from '../../../modules/objective/dtos/objective.dto';
import ObjectiveRepository from '../../../modules/objective/objective.repository';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ObjectiveList from './components/objetiveList/objectiveList';
import { Button, Input } from '@nextui-org/react';
import { t } from 'i18next';
import Filter from './components/filter/Filter';



const ObjetiveListPage = () => {
    const [objectives, setObjectives] = useState<ObjectiveDTO[]>([]);
    const [searcher, setSearcher] = useState<string>('');
    const navigate = useNavigate();

    const getObjectives = async (options: any = {}) => {
        const objectivesFinded = await ObjectiveRepository.find({
            relations: ['categories', 'keyResults', 'manager'],
            where: {
                name: `lk=${searcher}`,
                ...options
            }
        });
        setObjectives(objectivesFinded);
    }

    useEffect(() => {
        getObjectives()
    }, [])

    useEffect(() => {
        getObjectives()
    }, [searcher])

    return <div className='w-full'>
        <h2 className='text-primary font-paytoneOne'>
            {t('objective.title')}
        </h2>
        <div className='flex gap-3'>
            <Input className='w-full' placeholder={t('placeholder.finder')} startContent={<FontAwesomeIcon icon={faSearch} />} onChange={(e) => setSearcher(e.target.value)} />
            <Button color='primary' onClick={() => { navigate('/objective/create') }}>{t('objective.button.add')}</Button>
        </div>
        <Filter onFilter={(options) => {
            console.log(options)
            getObjectives(options);
        }} />
        <ObjectiveList objectives={objectives} />
    </div>
}

export default ObjetiveListPage;