import { Field, FieldProps } from "formik";
import StatusEnum from "../../../modules/keyResult/enum/status.enum";
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

interface IStatusInput {
    id: string;
    label: string;
    required?: boolean;
    value: StatusEnum;

    onSelect?: (value: StatusEnum) => void;

}


const StatusInput = (props: IStatusInput) => {
    const { t } = useTranslation();
    const getCssStatus = (status: StatusEnum) => {
        const statusCss = {
            AT_RISK: 'text-center font-bold bg-red-200 text-red-800 border border border-red-600 rounded p-3 text-sm',
            IN_TIME: 'text-center font-bold bg-green-200 text-green-800 border border border-green-600 rounded p-3 text-sm',
            LATE: 'text-center font-bold bg-yellow-200 text-yellow-800 border border border-yellow-600 rounded p-3 text-sm'
        }

        return statusCss[status];
    }

    return <Field id={props.id} name={props.id}>
        {({ form }: FieldProps) => {

            const select = (value: StatusEnum) => {
                form.setFieldValue(props.id, value);
            }

            return <Dropdown>
                <DropdownTrigger className="cursor-pointer">
                    <div className={getCssStatus(props.value)}>{t(`keyResult.statuses.${props.value}`)}</div>
                </DropdownTrigger>
                <DropdownMenu>
                    <DropdownItem>
                        <div className={getCssStatus(StatusEnum.IN_TIME)} onClick={() => {
                            select(StatusEnum.IN_TIME);
                            if (props.onSelect) props.onSelect(StatusEnum.IN_TIME)
                        }}>{t(`keyResult.statuses.${StatusEnum.IN_TIME}`)}</div>
                    </DropdownItem>
                    <DropdownItem>
                        <div className={getCssStatus(StatusEnum.LATE)} onClick={() => {
                            select(StatusEnum.LATE);
                            if (props.onSelect) props.onSelect(StatusEnum.LATE)
                        }}>{t(`keyResult.statuses.${StatusEnum.LATE}`)}</div>
                    </DropdownItem>
                    <DropdownItem>
                        <div className={getCssStatus(StatusEnum.AT_RISK)} onClick={() => {
                            select(StatusEnum.AT_RISK);
                            if (props.onSelect) props.onSelect(StatusEnum.AT_RISK)
                        }}>{t(`keyResult.statuses.${StatusEnum.AT_RISK}`)}</div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        }}
    </Field>
}

export default StatusInput;