import styles from './selectStatus.module.css';
import { Field } from "formik";
import StatusEnum from "../../enum/status.enum";
import InputLabel from '../../../../components/Input/InputLabel/InputLabel';
import { Select, SelectItem } from '@nextui-org/react';

interface ISelectStatusType {
    id: string;
    containerClassName?: string;
    label?: string;
    subLabel?: string;
    required?: boolean;
}

const SelectStatusType = (props: ISelectStatusType) => {
    return <Field as='select' name={props.id}>
        {({ field }: { field: any }) => (
            <Select label={props.label} description={props.subLabel} defaultSelectedKeys={[StatusEnum.IN_TIME]} {...field} >
                <SelectItem key={StatusEnum.IN_TIME} value={StatusEnum.IN_TIME}>A tiempo</SelectItem>
                <SelectItem key={StatusEnum.LATE} value={StatusEnum.LATE}>Atrasado</SelectItem>
                <SelectItem key={StatusEnum.AT_RISK} value={StatusEnum.AT_RISK}>En riesgo</SelectItem>
            </Select>
        )}
    </Field>
}

export default SelectStatusType;