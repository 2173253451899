import styles from './editKeyResult.module.css';
import { ReactNode, useEffect, useState } from 'react';
import { Formik, Form, FormikErrors, FormikTouched } from 'formik';
import NormalInput from '../../../components/Input/NormalInput/NormalInput';
import SelectGoalCategory from '../../../modules/goalCategory/components/selectGoalCategory/selectGoalCategory.component';
import InputTextArea from '../../../components/Input/InputTextArea/InputTextArea';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CreateKeyResultDTO from '../../../modules/keyResult/dto/createKeyResult.dto';
import createKeyResultValidation from './editKeyResultValidation';
import SelectUsers from '../../../modules/user/component/selectUsers/selectUsers.component';
import InputTimeFrame from '../../../components/Input/InputTimeFrame/InputTimeFrame';
import { RingLoader } from 'react-spinners';
import KeyResultRepository from '../../../modules/keyResult/keyResult.repository';
import KeyResultDTO from '../../../modules/keyResult/dto/keyResult.dto';
import StatusEnum from '../../../modules/keyResult/enum/status.enum';
import MeditionTypeEnum from '../../../modules/keyResult/enum/meditionType.enum';
import SelectStatusType from '../../../modules/keyResult/components/selectStatus/selectStatus';
import SelectMedition from '../../../modules/keyResult/components/selectMeditionType/selectMeditionType';
import { useTranslation } from 'react-i18next';
import { Button } from '@nextui-org/react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditKeyResult = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();

    const [keyResult, setKeyResult] = useState<KeyResultDTO | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false);

    const getKeyResult = async () => {
        setLoading(true);
        if (params.id) {
            const keyResultResult = await KeyResultRepository.findOne(params.id, { relations: ['manager', 'colaborators'] })
            setKeyResult(keyResultResult)
        }
        setLoading(false);
    }

    useEffect(() => {
        getKeyResult()
    }, [])

    const renderLoading = () => {
        return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <RingLoader color='#384FF0' size={35} />
        </div>
    }

    const initialValuesForm: CreateKeyResultDTO = {
        name: keyResult?.name || '',
        description: keyResult?.description || '',
        categories: keyResult?.categories || [],
        manager: keyResult?.manager || null,
        colaborators: keyResult?.colaborators || [],
        notify: keyResult?.notify || [],
        startDate: keyResult?.startDate ? new Date(keyResult.startDate) : new Date(),
        endDate: keyResult?.endDate ? new Date(keyResult.endDate) : new Date(),
        status: keyResult?.status || StatusEnum.IN_TIME,
        meditionIntendedValue: keyResult?.meditionIntendedValue || 0,
        meditionType: keyResult?.meditionType || MeditionTypeEnum.NUMERIC,
        meditionValue: keyResult?.meditionValue || 0
    }

    const flattenObject = (ob: any) => {
        var toReturn: any = {};

        for (var i in ob) {
            if (!ob.hasOwnProperty(i)) continue;

            if (typeof ob[i] == "object" && ob[i] !== null) {
                var flatObject = flattenObject(ob[i]);
                for (var x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) continue;

                    toReturn[i + "." + x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    }

    const handlerSubmitForm = async (keyResultEdited: CreateKeyResultDTO) => {
        await KeyResultRepository.update(keyResult?.id || 0, keyResultEdited as KeyResultDTO)
        navigate(`/keyResult/detail/${keyResult?.id}`);
    }

    return <div className='container mx-auto text-foreground'>
        <h2 className='text-primary font-paytoneOne gap-3 flex items-center'>
            <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => navigate(-1)} />
            {t('keyResult.edit')}
        </h2>
        <p>{t('keyResult.editLabel')}</p>
        {
            isLoading ? renderLoading() :
                <Formik<CreateKeyResultDTO>
                    initialValues={initialValuesForm}
                    validationSchema={createKeyResultValidation()}
                    onSubmit={(values, actions) => {
                        handlerSubmitForm(values);
                    }}
                >
                    {({ values, errors, handleReset, setFieldValue, touched, isValid, validateForm, setErrors }: {
                        values: CreateKeyResultDTO,
                        errors: FormikErrors<CreateKeyResultDTO>,
                        handleReset: () => void,
                        setFieldValue: (field: string, value: any) => void,
                        touched: FormikTouched<CreateKeyResultDTO>,
                        isValid: boolean,
                        validateForm: (values: CreateKeyResultDTO) => Promise<FormikErrors<CreateKeyResultDTO>>,
                        setErrors: (fields: any) => void;
                    }): ReactNode => {
                        return <Form className='flex flex-col gap-5 py-3'>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <NormalInput
                                    id='name'
                                    label={t('keyResult.inputs.name.label')}
                                    required={true}
                                    placeholder='Ejemplo: Aumentar en un 20% las reuniones'
                                    error={errors.name}
                                    touched={touched.name}
                                />
                                <SelectGoalCategory
                                    containerClassName={styles.createKeyResultFormItem}
                                    values={values.categories}
                                />
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <InputTextArea
                                    id='description'
                                    label={t('keyResult.inputs.description.label')}
                                />
                                <InputTimeFrame
                                    id='Date'
                                    label={t('keyResult.inputs.period.label')}
                                    required={true}
                                    errors={{
                                        startDate: errors.startDate as string,
                                        endDate: errors.endDate as string
                                    }}
                                    toucheds={{
                                        startDate: touched.startDate as boolean,
                                        endDate: touched.endDate as boolean
                                    }}
                                    onSelectQuarter={(q) => {
                                        const dateFrom = new Date();
                                        const dateTo = new Date();
                                        if (q === 'Q1') {
                                            dateFrom.setDate(1)
                                            dateFrom.setMonth(0);
                                            dateTo.setDate(1);
                                            dateTo.setMonth(3);
                                            dateTo.setDate(0)

                                            setFieldValue('startDate', dateFrom)
                                            setFieldValue('endDate', dateTo)
                                        }
                                        if (q === 'Q2') {
                                            dateFrom.setDate(1)
                                            dateFrom.setMonth(3);
                                            dateTo.setDate(1);
                                            dateTo.setMonth(6);
                                            dateTo.setDate(0);

                                            setFieldValue('startDate', dateFrom)
                                            setFieldValue('endDate', dateTo)
                                        }
                                        if (q === 'Q3') {
                                            dateFrom.setDate(1)
                                            dateFrom.setMonth(6);
                                            dateTo.setDate(1);
                                            dateTo.setMonth(9);
                                            dateTo.setDate(0);

                                            setFieldValue('startDate', dateFrom)
                                            setFieldValue('endDate', dateTo)
                                        }
                                        if (q === 'Q4') {
                                            dateFrom.setDate(1)
                                            dateFrom.setMonth(9);
                                            dateTo.setDate(1);
                                            dateTo.setMonth(12);
                                            dateTo.setDate(0);

                                            setFieldValue('startDate', dateFrom)
                                            setFieldValue('endDate', dateTo)
                                        }
                                    }}
                                />
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <SelectStatusType id={'status'} label={t('keyResult.inputs.status.label')} />
                                <SelectMedition id='meditionType' label={t('keyResult.inputs.meditionType.label')} />
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <NormalInput
                                    id='meditionValue'
                                    label={t('keyResult.inputs.meditionValue.label')}
                                    required={true}
                                    placeholder='10'
                                    error={errors.meditionValue as string}
                                    touched={touched.meditionValue as boolean}
                                />
                                <NormalInput
                                    id='meditionIntendedValue'
                                    label={t('keyResult.inputs.meditionIntendedValue.label')}
                                    required={true}
                                    placeholder='10'
                                    error={errors.meditionIntendedValue as string}
                                    touched={touched.meditionIntendedValue as boolean}
                                />
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <SelectUsers
                                    id='colaborators'
                                    label={t('keyResult.inputs.colaborators.label')}
                                    containerClassName=''
                                    subLabel={t('keyResult.inputs.colaborators.helper')}
                                    values={values.colaborators}
                                    required={true}
                                    error={errors.colaborators as string}
                                />
                                <SelectUsers
                                    id='notify'
                                    label={t('keyResult.inputs.notify.label')}
                                    subLabel={t('keyResult.inputs.notify.helper')}
                                    containerClassName=''
                                    values={values.notify}
                                />
                            </div>
                            <Button color='primary' isDisabled={!isValid} type='submit'>{t('keyResult.buttons.continue')}</Button>
                        </Form>
                    }}
                </Formik>
        }

    </div>

}

export default EditKeyResult;