import axios from 'axios'
import CreateObjectiveDTO from './dtos/createObjective.dto'
import ObjectiveDTO from './dtos/objective.dto'
import FindOptionsDTO from '../common/dto/findOptions.dto'

namespace ObjectiveRepository {
  export const save = async (
    objective: CreateObjectiveDTO
  ): Promise<ObjectiveDTO> => {
    try {
      return (await axios.post<ObjectiveDTO>(`/objective`, objective)).data
    } catch (err) {
      throw new Error()
    }
  }

  export const find = async (
    options: FindOptionsDTO<ObjectiveDTO>
  ): Promise<ObjectiveDTO[]> => {
    try {
      const formatedOptions = encodeURIComponent(JSON.stringify(options))
      const objectives = (
        await axios.get<ObjectiveDTO[]>(`/objective?query=${formatedOptions}`)
      ).data

      return objectives.map(objective => {
        objective.startDate = new Date(objective.startDate)
        objective.endDate = new Date(objective.endDate)
        return objective
      })
    } catch (err) {
      throw Error()
    }
  }

  export const findOne = async (
    id: number | string,
    options: FindOptionsDTO<ObjectiveDTO>
  ): Promise<ObjectiveDTO> => {
    try {
      const formatedOptions = encodeURIComponent(JSON.stringify(options))
      const objective = (
        await axios.get<ObjectiveDTO>(
          `/objective/${id}?query=${formatedOptions}`
        )
      ).data
      objective.startDate = new Date(objective.startDate)
      objective.endDate = new Date(objective.endDate)
      return objective
    } catch (err) {
      throw Error()
    }
  }

  export const update = async (
    id: number,
    objective: ObjectiveDTO | Partial<ObjectiveDTO>
  ): Promise<ObjectiveDTO> => {
    try {
      return (await axios.put<ObjectiveDTO>(`/objective/${id}`, objective)).data
    } catch (err) {
      throw new Error()
    }
  }

  export const remove = async (id: number): Promise<void> => {
    await axios.delete(`/objective/${id}`)
  }
}

export default ObjectiveRepository
