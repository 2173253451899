import { FieldArray, FieldArrayRenderProps } from "formik";
import { ReactNode, useEffect, useState } from "react";
import styles from './InputSelectionMultiple.module.css'
import InputLabel from "../InputLabel/InputLabel";
import { Card, CardBody, Input, Listbox, ListboxSection } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

interface IInputSelectionMultiple<T> {
    id: string;
    label: string;
    subLabel?: string;
    placeholder?: string;

    containerClassName?: string;
    inputContainerClassName?: string;
    optionsContainerClassName?: string;
    itemContainerClassName?: string;
    endContent?: ReactNode;

    handlerFindOptions: (finder: string) => Promise<Array<T>>;
    optionItemTemplate: (
        option: T,
        insert: (index: number, value: T) => void,
        clear: () => void
    ) => ReactNode;
    optionContainerTemplate?: (
        option: T[],
        insert: (index: number, value: T) => void,
        clear: () => void
    ) => ReactNode;
    valueItemTemplate: (
        value: T,
        index: number,
        remove: (index: number) => void,
        clear: () => void
    ) => ReactNode;
    createTemplate?: (
        options: T[],
        finder: string,
        insert: (index: number, value: T) => void,
        clear: () => void
    ) => ReactNode;

    values: T[];
}

const InputSelectionMultiple = <T,>(props: IInputSelectionMultiple<T>) => {
    const [finder, setFinder] = useState('');
    const [options, setOptions] = useState<T[]>([]);
    const { t } = useTranslation();

    const handlerFindOptions = async (finderString: string) => {
        if (!finder) return setOptions(Object.assign([], []))

        const optionsItems = await props.handlerFindOptions(finderString);
        return setOptions(Object.assign([], optionsItems));
    }

    useEffect(() => {
        handlerFindOptions(finder)
    }, [finder])

    const clear = () => {
        setFinder('');
        setOptions([]);
    }

    return <FieldArray name={props.id}>
        {
            ({ insert, remove }: FieldArrayRenderProps) => {
                return <div>
                    <div>
                        <Input
                            label={props.label}
                            description={props.subLabel}
                            id={`${props.id}_input`}
                            value={finder}
                            onChange={async (e) => {
                                setFinder(e.target.value);
                            }}
                            endContent={props.endContent}
                        />
                    </div>
                    <div className="relative">
                        {
                            props.optionContainerTemplate ?
                                props.optionContainerTemplate(options, insert, clear)
                                :
                                <Listbox className={`absolute z-20 border rounded bg-background ${(options.length > 0 || finder.length >= 3) ? 'block' : 'hidden'}`}>
                                    {(props.createTemplate && finder.length >= 3) && props.createTemplate(options, finder, insert, clear) as any}
                                    <ListboxSection title={t('objective.inputs.category.results')}>
                                        {options.map(option => props.optionItemTemplate(option, insert, clear)) as any}
                                    </ListboxSection>
                                </Listbox>
                        }
                    </div>
                    <div className="flex gap-3 mt-3">
                        {props.values.map(
                            (value, index) =>
                                props.valueItemTemplate(value, index, remove, clear)
                        )}
                    </div>
                </div>
            }
        }
    </FieldArray>
}

export default InputSelectionMultiple;