import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthRepository from "../../modules/auth/auth.repository";
import { saveProfile } from "../../modules/auth/profile.reducer";
import { useDispatch } from "react-redux";

const Login = () => {
    const [paramsSearcher] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tryLogin = async () => {
        try {
            const email = paramsSearcher.get('email')
            const password = paramsSearcher.get('password');

            if (!email || !password) throw new Error('Debe indicar email y/o clave.');

            const authResponse = await AuthRepository.login(email, password);
            localStorage.setItem('token', authResponse.token);
            const profile = await AuthRepository.getProfile();
            dispatch(saveProfile(profile))
            navigate('/objective')
        }
        catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        tryLogin();
    }, [])

    return <></>
}

export default Login;